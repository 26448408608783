import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectBlockById, selectAllBlocks } from '../selectors';
import { loadBlockList } from '../actions';

export default function useBlock(id) {
  const dispatch = useDispatch();
  const block = useSelector((state) => selectBlockById(state, id));
  useEffect(() => {
    dispatch(loadBlockList());
  }, [dispatch]);
  return block;
}

export function useBlockNames(ids) {
  const blocks = useSelector((state) => selectAllBlocks(state));
  const filteredBlocks = blocks.filter((d) => ids.some((id) => id === d.id));
  return filteredBlocks.reduce((a, block) => {
    a[block.id] = block.name;
    return a;
  }, {});
}
