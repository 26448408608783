import React, { useCallback, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, actions } from 'farmx-redux-core';
import { useTranslation } from 'react-i18next';
import { Table, Tooltip } from 'antd';
import Moment from 'react-moment';
import moment from 'moment';
import BooleanTag from '../BooleanTag';
import SensorAlarmButton from './SensorAlarmButton';
import PumpStateTag from './PumpStateTag';
import ControlSummaryRefreshContext from './RefreshContext';
import styles from './CommonStyles.less';

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few secs',
    ss: '%d secs',
    m: 'a min',
    mm: '%d mins',
    h: 'an hr',
    hh: '%d hrs',
    d: 'a day',
    dd: '%d days',
    w: 'a week',
    ww: '%d weeks',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
});

const {
  selectBlockPressureSensors,
  selectBlockPumpController,
  selectBlockFlowMeter,
} = selectors;

const {
  loadSensorStatus,
} = actions;

export default function BlockControlSensorList({ blockId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const refreshId = useContext(ControlSummaryRefreshContext);

  const pressureSensors = useSelector(
    (state) => selectBlockPressureSensors(state, blockId),
  );
  const pumpController = useSelector(
    (state) => selectBlockPumpController(state, blockId),
  );
  const flowMeter = useSelector(
    (state) => selectBlockFlowMeter(state, blockId),
  );
  const {
    identifier: pumpControllerIdentifier,
    type: pumpControllerType,
  } = pumpController;
  const {
    identifier: flowMeterIdentifier,
    type: flowMeterType,
  } = flowMeter || {};

  const pressureSensorIdentifiers = JSON.stringify(
    pressureSensors.map((sensor) => sensor.identifier),
  );

  const refreshPressureSensorStatus = useCallback(() => {
    if (refreshId) {
      JSON.parse(pressureSensorIdentifiers).forEach((identifier) => {
        const sensorParams = { type: 'water_pressure', identifier };
        dispatch(loadSensorStatus(sensorParams));
      });
    }
  }, [dispatch, refreshId, pressureSensorIdentifiers]);

  useEffect(() => {
    refreshPressureSensorStatus();
  }, [refreshPressureSensorStatus]);

  useEffect(() => {
    const sensorParams = {
      type: pumpControllerType,
      identifier: pumpControllerIdentifier,
    };
    if (pumpControllerIdentifier) {
      dispatch(loadSensorStatus(sensorParams));
    }
  }, [dispatch, pumpControllerType, pumpControllerIdentifier]);

  useEffect(() => {
    if (refreshId && flowMeterIdentifier && flowMeterType) {
      const sensorParams = { type: flowMeterType, identifier: flowMeterIdentifier };
      dispatch(loadSensorStatus(sensorParams));
    }
  }, [dispatch, refreshId, flowMeterType, flowMeterIdentifier]);

  function renderSensorName(sensor) {
    if (!sensor.name) {
      return <span>{t('No Name')}</span>;
    }
    return (
      <Tooltip title={sensor.name}>
        <span>{sensor.name}</span>
      </Tooltip>
    );
  }

  function renderPressure(sensor) {
    const { status } = sensor;
    if (!status || status.waterPressure === undefined || status.waterPressure === null) return null;
    return (
      <div>{`${status.waterPressure.toFixed(1)} psi`}</div>
    );
  }

  function renderSensorConnectivity(sensor) {
    if (!sensor.status) return null;
    return (
      <BooleanTag value={sensor.status.online} labels={[t('online'), t('offline')]} />
    );
  }

  function renderLatestDate(sensor) {
    if (!sensor.status || !sensor.status.latestDate) return null;
    const { latestDate: date } = sensor.status;
    return (
      <Tooltip title={<Moment format="YYYY-MM-DD h:mm:ss A" local>{date}</Moment>} placement="bottom">
        <span>
          <Moment fromNow>{date}</Moment>
        </span>
      </Tooltip>
    );
  }

  function renderSensorAlarms(sensor) {
    return (
      <SensorAlarmButton
        type={sensor.type}
        identifier={sensor.identifier}
        alarmCount={sensor.status && sensor.status.alarmCount}
      />
    );
  }

  function renderFlow(sensor) {
    const { status } = sensor;
    if (!status || status.flowRate === undefined || status.flowRate === null) {
      return null;
    }
    return (
      <div>{`${status.flowRate.toFixed(1)} GPM`}</div>
    );
  }

  function widthCalc(columns) {
    const maxWidth = 100;
    const result = columns().map((column) => {
      if (column.key !== 'alarms') {
        return {
          ...column,
          width: `${(maxWidth - 10) / (columns().length - 1)}%`,
        };
      }
      return column;
    });
    return result;
  }

  const pressureColumns = () => [
    {
      title: t('Name'),
      key: 'name',
      className: styles.scheduleItemHeader,
      render: (sensor) => renderSensorName(sensor),
    },
    {
      title: t('Connectivity'),
      key: 'connectivity',
      className: styles.connect,
      render: (sensor) => renderSensorConnectivity(sensor),
    },
    {
      title: t('Pressure'),
      key: 'pressure',
      className: styles.stateHeader,
      render: (sensor) => renderPressure(sensor),
    },
    {
      title: t('Last Data'),
      key: 'lastData',
      className: styles.lastdata,
      render: (sensor) => renderLatestDate(sensor),
    },
    {
      title: t('Alarms'),
      key: 'alarms',
      className: styles.alarmsHeader,
      width: 100,
      render: (sensor) => renderSensorAlarms(sensor),
    },
  ];

  const flowMeterColumns = () => [
    {
      title: t('Name'),
      key: 'name',
      className: styles.scheduleItemHeader,
      render: (sensor) => renderSensorName(sensor),
    },
    {
      title: t('Connectivity'),
      key: 'connectivity',
      className: styles.connect,
      render: (sensor) => renderSensorConnectivity(sensor),
    },
    {
      title: t('Flow'),
      key: 'flow',
      className: styles.stateHeader,
      render: (sensor) => renderFlow(sensor),
    },
    {
      title: t('Last Data'),
      key: 'lastData',
      className: styles.lastdata,
      render: (sensor) => renderLatestDate(sensor),
    },
    {
      title: t('Alarms'),
      key: 'alarms',
      className: styles.alarmsHeader,
      width: 100,
      render: (sensor) => renderSensorAlarms(sensor),
    },
  ];

  const pumpControllerColumns = () => [
    {
      title: t('Name'),
      key: 'name',
      className: styles.scheduleItemHeader,
      render: (sensor) => renderSensorName(sensor),
    },
    {
      title: t('Connectivity'),
      key: 'connectivity',
      className: styles.connect,
      render: (sensor) => renderSensorConnectivity(sensor),
    },
    {
      title: t('State'),
      className: styles.stateHeader,
      key: 'state',
      render: (sensor) => (
        <PumpStateTag
          state={sensor.status && sensor.status.controlStatus && sensor.status.controlStatus.status}
          loading={sensor.status && sensor.status.loading}
          online={sensor.status && sensor.status.online}
        />
      ),
    },
    {
      title: t('Last Data'),
      key: 'lastData',
      className: styles.lastdata,
      render: (sensor) => renderLatestDate(sensor),
    },
    {
      title: t('Alarms'),
      key: 'alarms',
      className: styles.alarmsHeader,
      width: 100,
      render: (sensor) => renderSensorAlarms(sensor),
    },
  ];

  const pumpControllers = pumpControllerIdentifier ? [pumpController] : null;

  return (
    <div>
      {pumpControllers && (
        [
          <span className={styles.subheader} key="header">{t('Pump Control')}</span>,
          <Table
            key="table"
            dataSource={pumpControllers}
            columns={widthCalc(pumpControllerColumns)}
            rowKey={(record) => record.identifier}
            size="small"
            className={styles['inner-table']}
            pagination={false}
          />,
        ]
      )}
      {flowMeter && (
        [
          <span className={styles.subheader} key="header">{t('Flow Meter')}</span>,
          <Table
            key="table"
            dataSource={[flowMeter]}
            columns={widthCalc(flowMeterColumns)}
            rowKey={(record) => record.identifier}
            size="small"
            className={styles['inner-table']}
            pagination={false}
          />,
        ]
      )}
      <span className={styles.subheader}>{t('Pressure Sensors')}</span>
      <Table
        dataSource={pressureSensors}
        columns={widthCalc(pressureColumns)}
        rowKey={(record) => record.identifier}
        size="small"
        className={styles['inner-table']}
        pagination={false}
      />
    </div>
  );
}

BlockControlSensorList.propTypes = {
  blockId: PropTypes.number.isRequired,
};
