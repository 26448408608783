const getSelectedBlock = (state) => state.clientData.selected.block;
const getSelectedRanch = (state) => state.clientData.selected.ranch;
const getSelectedBlocks = (state) => state.clientData.selected.blocks;
const getSelectedRanches = (state) => state.clientData.selected.ranches;
const getEntitySidebarCollapseState = (state) => state.clientData.selected.entitySidebarState;
const getUrlLoad = (state) => state.clientData.selected.urlLoad;

export {
  getSelectedBlock,
  getSelectedBlocks,
  getSelectedRanch,
  getSelectedRanches,
  getEntitySidebarCollapseState,
  getUrlLoad,
};
