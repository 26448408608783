import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Badge } from 'antd';
import { BellFilled } from '@ant-design/icons';
import styles from './CommonStyles.less';

function getAlarmListUrl({ type, identifier }) {
  const params = {
    sensorType: type,
    sensorIdentifier: identifier,
    logLevel: 'error',
    active: true,
  };
  const paramStr = new URLSearchParams(params).toString();
  return `/events?${paramStr}`;
}

export default function SensorAlarmButton({ type, identifier, alarmCount }) {
  return (
    <Link to={getAlarmListUrl({ type, identifier })}>
      <Badge
        count={alarmCount}
      >
        <Button
          className={styles.bellIconButton}
          icon={<BellFilled className={styles.bellIconSize} />}
        />
      </Badge>
    </Link>
  );
}

SensorAlarmButton.propTypes = {
  type: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  alarmCount: PropTypes.number,
};

SensorAlarmButton.defaultProps = {
  alarmCount: null,
};
