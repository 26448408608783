import { combineReducers } from 'redux';
import entities from './entities';
import ranches from './ranches';
import blocks from './blocks';
import plans from './plans';

const farmReducer = combineReducers({
  entities,
  ranches,
  blocks,
  plans,
});

export default farmReducer;
