import { farmApi } from 'farmx-api';
import { createFarmSlice } from './farmSlice';

const {
  getBlockList,
  getBlockDetail,
} = farmApi;

const {
  loadListThunk: loadBlockList,
  loadDetailThunk: loadBlockDetail,
  slice: blocksSlice,
} = createFarmSlice(
  'blocks',
  getBlockList,
  getBlockDetail,
  (state) => state.farmData.blocks,
);

export {
  loadBlockDetail,
  loadBlockList,
};

export default blocksSlice.reducer;
