import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { StyleSheet, css } from 'aphrodite';
import DropdownModal from './DropdownModal';
import styles from '../CommonStyle.less';
import SensorSelectContext from './SensorSelectContext';

const { Option } = Select;

export default function SensorTypeSelect(props) {
  const {
    selectedType, sensorTypes, onChange, disabled,
  } = props;
  const { t } = useTranslation();
  const [modal, setModalState] = useState(false);
  const mobileScreen = window.innerWidth < 599;
  let dropdownStyle = null;
  if (mobileScreen) dropdownStyle = { minWidth: '60%' };
  const dropdownStyleValues = useContext(SensorSelectContext);

  const getDropDownClass = (dropDownStyle) => {
    let styleClassName;
    if (window.innerWidth > 599) {
      if (dropDownStyle && dropDownStyle.leftValue) {
        const createdStyles = StyleSheet.create({
          leftposition: {
            left: `${dropDownStyle.leftValue.current} !important`,
            width: `${dropDownStyle.minWidthValue.current} !important`,
          },
        });
        styleClassName = `${css(createdStyles.leftposition)}`;
      }
    } if (window.innerWidth < 599) {
      styleClassName = `${styles.selectDropDown} `;
    }
    return styleClassName;
  };

  return (
    <Select
      disabled={(sensorTypes.length === 1 && selectedType) || disabled}
      style={{ width: '100%' }}
      allowClear
      value={selectedType}
      placeholder={t('Type')}
      optionLabelProp="label"
      dropdownMatchSelectWidth={false}
      onChange={onChange}
      onSelect={!mobileScreen ? () => setModalState(false) : null}
      dropdownStyle={dropdownStyle}
      onFocus={() => setModalState(true)}
      open={mobileScreen ? modal : undefined}
      dropdownClassName={getDropDownClass(dropdownStyleValues)}
      dropdownRender={mobileScreen ? (menu) => (
        <div className={styles.dropdownOptionDiv}>
          <DropdownModal
            title="SensorType Select"
            displayOptions={menu}
            mobileScreen={mobileScreen}
            onClick={() => setModalState(false)}
          />
        </div>
      ) : undefined}
    >
      {sensorTypes.map(([key, value]) => (
        <Option key={key} label={value.name} data-testid={`${value.name}`}>{value.name}</Option>
      ))}
    </Select>
  );
}

const sensorTypePropType = PropTypes.string;

SensorTypeSelect.propTypes = {
  selectedType: sensorTypePropType,
  sensorTypes: PropTypes.arrayOf(PropTypes.any),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

SensorTypeSelect.defaultProps = {
  selectedType: undefined,
  sensorTypes: [],
  onChange: () => {},
  disabled: false,
};
