import React, { useState, useEffect } from 'react';
import { controlApi } from 'farmx-api';
import { useSelector } from 'react-redux';
import {
  Form, Button, Select, DatePicker, message, Modal,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { selectors } from 'farmx-redux-core';
import PropTypes from 'prop-types';
import InputTime from './InputTime';
import InputVolume from './InputVolume';
import BlockSelect from '../../BlockSelect';
import styles from './InputStyles.less';

const { selectBlockSchedule } = selectors;
const scheduleMethods = [
  {
    value: 'dates',
    label: 'Date Range',
  },
  {
    value: 'runtime',
    label: 'Run Time',
  },
  {
    value: 'volume',
    label: 'Water Volume',
  },
];

const ScheduleForm = ({
  onCancel,
  onSubmit,
  onChange,
  dateRange,
  blocks,
  scheduleId,
}) => {
  const [form] = Form.useForm();
  const [method, setMethod] = useState('dates');
  const { t } = useTranslation();
  const {
    createScheduledIrrigationEvent,
    updateScheduledIrrigationEvent,
    deleteScheduledIrrigationEvent,
  } = controlApi;
  const [block, setBlock] = useState([]);
  const [loading, setLoadingState] = useState(false);
  const [btnEnabled, setButtonState] = useState(true);
  const [paramDateRange, setParamDateRange] = useState([]);
  const [deleteConfirmModalState, setDeleteConfirmModalState] = useState({
    state: false, dateRange: null,
  });
  const [validationStatus, setValidationStatus] = useState(false);

  let blockId;
  if (scheduleId && blocks) {
    const blkid = blocks[0];
    blockId = blkid;
  }
  const schedules = useSelector((state) => selectBlockSchedule(state, blockId));

  useEffect(() => {
    if (blocks) {
      if (blocks) setBlock(blocks);
      if (dateRange) {
        if (dateRange) setParamDateRange(dateRange);
      }
    }
    if (schedules) {
      const schedule = schedules.scheduled_events.filter((d) => d.id === scheduleId);
      if (schedule.length > 0) {
        setParamDateRange(
          [moment(Date.parse(schedule[0].start_date)),
            moment(Date.parse(schedule[0].stop_date))],
        );
      }
    }
  }, [blocks, dateRange, scheduleId, schedules]);

  function processRequest(startDate, stopDate, blk, obj, toDelete) {
    const submitObj = obj || {};
    const callApi = async () => {
      try {
        let success;
        if (scheduleId && blockId && !toDelete) {
          success = await updateScheduledIrrigationEvent({
            id: scheduleId,
            start_date: startDate,
            stop_date: stopDate,
            block: blockId,
          });
        } else if (toDelete && scheduleId) {
          success = await deleteScheduledIrrigationEvent({
            id: scheduleId,
          });
        } else if (startDate && stopDate && blk) {
          success = await createScheduledIrrigationEvent({
            start_date: startDate,
            stop_date: stopDate,
            block: blk,
          });
        }
        if (success) {
          message.success(t('Success'));
          setLoadingState(false);
          submitObj.status = 'Created';
          onSubmit(submitObj);
        } else {
          message.error(t('Error'));
          setLoadingState(false);
          submitObj.status = 'Error';
          onSubmit(submitObj);
        }
      } catch (error) {
        message.error(t('Error'));
        setLoadingState(false);
        submitObj.status = 'Created';
        onSubmit(submitObj);
      }
    };
    callApi();
  }
  function onConfirmDelete() {
    setLoadingState(true);
    processRequest(null, null, null, {}, true);
  }
  function onFinish(values) {
    if (values.method === 'dates' && !paramDateRange.length) {
      setValidationStatus(true);
    }

    if (values.method === 'runtime') {
      const formObj = values;
      formObj.endTime = moment(values.startTime).add(values.runTime.value, values.runTime.units);
    }

    const submitObj = {
      block: values.block,
      method: values.method,
      startDate: (paramDateRange
          && paramDateRange[0]
      ) ? paramDateRange[0].format('YYYY-MM-DDTHH:mm:ss.sss') : null,
      endDate: (paramDateRange
        && paramDateRange[1]
      ) ? paramDateRange[1].format('YYYY-MM-DDTHH:mm:ss.sss') : null,
      startTime: values.startTime ? values.startTime.format('YYYY-MM-DDTHH:mm:ss.sss') : null,
      runTime: values.runTime,
      endTime: values.endTime ? values.endTime.format('YYYY-MM-DDTHH:mm:ss.sss') : null,
      volume: values.volume,
    };

    let endTime;
    if (values.method === 'runtime') {
      endTime = moment(values.startTime).add(values.runTime.value, values.runTime.units);
    }

    const startDate = (paramDateRange
      && paramDateRange[0]
    ) ? moment(paramDateRange[0]).toISOString() : moment(values.startTime).toISOString();
    const stopDate = (paramDateRange
      && paramDateRange[1]
    ) ? moment(paramDateRange[1]).toISOString() : moment(endTime).toISOString();

    if (moment(startDate).isSame(moment(stopDate))) {
      if (paramDateRange.length) message.error('StartDate and StopDate are same');
    } else if (moment(startDate).isBefore(moment(stopDate))) {
      setLoadingState(true);
      // Call to back-end API goes here for each block id
      values.block.forEach((d) => {
        processRequest(moment(moment(startDate).format('YYYY-MM-DD HH:mm')).toISOString(),
          moment(moment(stopDate).format('YYYY-MM-DD HH:mm')).toISOString(), d, submitObj);
      });
    }
  }

  function onFormEdit(val) {
    if (onChange) onChange(val);
    if (val && val.length > 1) {
      setParamDateRange(val);
      setValidationStatus(false);
    } else {
      setParamDateRange([]);
      setValidationStatus(true);
    }
    setButtonState(false);
  }

  function renderBlock() {
    return (
      <Form.Item
        name="block"
        label={t('Block(s)')}
        rules={[
          {
            required: true,
            message: t('Please select block(s)'),
          },
        ]}
      >
        <BlockSelect
          placeholder={t('Select Blocks To Irrigate')}
          mode="multiple"
          onChange={(d) => { setBlock(d); setButtonState(false); }}
          defaultOption={block.length > 0 ? block : blocks}
        />
      </Form.Item>
    );
  }
  function renderDateRangeItems() {
    return (
      <Form.Item
        label={t('Choose Dates')}
        help={validationStatus ? t('Please select Start Date and End Date') : null}
        validateStatus={validationStatus ? 'error' : 'validating'}
        rules={[
          {
            required: true,
            message: t('Please select Start Date and End Date'),
          },
        ]}
      >
        <DatePicker.RangePicker
          showTime={{ format: 'HH:mm' }}
          onChange={onFormEdit}
          disabled={method !== 'dates'}
          value={paramDateRange}
          format="YYYY-MM-DD HH:mm"
        />
      </Form.Item>
    );
  }

  function renderRuntimeItems() {
    return (
      <div>
        <Form.Item
          name="startTime"
          label={t('Start Time')}
          rules={[
            {
              required: true,
              message: t('Please select Start Time'),
            },
          ]}
        >
          <DatePicker
            showTime={{ format: 'HH:mm' }}
            onChange={onFormEdit}
            onOk={null}
            format="YYYY-MM-DD HH:mm"
          />
        </Form.Item>
        <Form.Item name="runTime" label={t('Run-time')}>
          <InputTime disabled={method !== 'runtime'} onChange={onFormEdit} />
        </Form.Item>
      </div>
    );
  }

  function renderVolumeItems() {
    return (
      <div>
        <Form.Item
          name="startTime"
          label={t('Start Time')}
          rules={[
            {
              required: true,
              message: t('Please select Start Time'),
            },
          ]}
        >
          <DatePicker
            showTime={{ format: 'HH:mm' }}
            onChange={onFormEdit}
            onOk={null}
            format="YYYY-MM-DD HH:mm"
          />
        </Form.Item>
        <Form.Item name="volume" label={t('Volume')}>
          <InputVolume disabled={method !== 'volume'} onChange={onFormEdit} />
        </Form.Item>
      </div>
    );
  }
  function onDelete() {
    return (
      <Modal
        title={t('Confirm delete schedule')}
        centered
        visible={deleteConfirmModalState.state}
        closable
        onOk={() => {
          onConfirmDelete();
          setDeleteConfirmModalState({ state: false, dateRange: null });
        }}
        onCancel={() => {
          setDeleteConfirmModalState({ state: false, dateRange: paramDateRange });
        }}
        maskClosable={false}
        destroyOnClose
        width={400}
        okText={t('Delete')}
        okButtonProps={{ style: { background: 'red', borderColor: 'red' } }}
      >
        {t('Do you really want to delete?')}
      </Modal>
    );
  }

  const saveBtnStyle = { background: 'green', borderColor: 'green', color: 'white' };
  function getButton(val) {
    if (val) {
      return (
        <div className={styles.updateFormButtons}>
          {window.innerWidth > 599
            ? <Button htmlType="button" onClick={onCancel}>{t('Cancel')}</Button> : null}
          <Button
            type="primary"
            htmlType="button"
            onClick={() => {
              if (moment(moment().format()).isBetween(paramDateRange[0], paramDateRange[1])) {
                setDeleteConfirmModalState({ state: true, dateRange: paramDateRange });
              } else onConfirmDelete();
            }}
            danger
          >
            {t('Delete')}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={btnEnabled}
            style={!btnEnabled ? saveBtnStyle : null}
          >
            {t('Save')}
          </Button>
        </div>
      );
    } return <Button type="primary" htmlType="submit">{t('Submit')}</Button>;
  }

  return (
    <div className="formContainer" id="form-update">
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          method: 'dates',
          chooseDates: dateRange,
          block: blocks,
        }}
        onFinish={onFinish}
        onChange={onFormEdit}
        scrollToFirstError
      >
        {
          // To select block
          renderBlock()
        }

        <Form.Item
          label={t('Schedule method')}
          name="method"
          rules={[
            {
              required: true,
              message: t('Please select method'),
            },
          ]}
        >
          <Select
            placeholder={t('Select Schedule Method')}
            value={method}
            onChange={(d) => setMethod(d)}
            options={scheduleMethods}
          />
        </Form.Item>
        {method === 'dates' && renderDateRangeItems()}
        {method === 'runtime' && renderRuntimeItems()}
        {method === 'volume' && renderVolumeItems()}
        {deleteConfirmModalState.state ? onDelete() : null}
        <Form.Item>
          {loading ? <LoadingOutlined />
            : getButton(scheduleId)}
        </Form.Item>
      </Form>
    </div>
  );
};

ScheduleForm.propTypes = {
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  dateRange: PropTypes.arrayOf(PropTypes.any),
  blocks: PropTypes.arrayOf(PropTypes.any),
  scheduleId: PropTypes.number,
};

ScheduleForm.defaultProps = {
  onCancel: () => null,
  onChange: () => null,
  onSubmit: () => null,
  dateRange: null,
  blocks: null,
  scheduleId: null,
};

export default ScheduleForm;
