/* eslint-disable react/jsx-fragments */
/* eslint-disable no-plusplus */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Descriptions,
  Dropdown,
  Button,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import { isMobile } from 'react-device-detect';
import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { SettingOutlined } from '@ant-design/icons';
import { FaList, FaMapMarkedAlt } from 'react-icons/fa';
import Map from './Map';
import { prepareZeroIslandSensors } from './prepareZeroIslandSensors';
import { MapSettingsMenu } from './MapSettingsMenu';
import { prepareNumberForDisplay, sensorTypeAllowedForMode } from './constants';
import './MapByRanchId.less';
import { ListByRanchId } from './ListByRanchId';

const isEqual = require('react-fast-compare');

function renderOptionalDescription(label, value, optional) {
  if (optional && value === undefined) {
    return null;
  }

  const longClassName = `${label}-${value}`.replace(/\s+/g, '-').toLowerCase();
  const shortClassName = `${label}`.replace(/\s+/g, '-').toLowerCase();
  return (
    <Descriptions.Item label={label} className={`${shortClassName} ${longClassName}`}>{Boolean(value) && value}</Descriptions.Item>
  );
}

const {
  loadRanchDetail,
  loadAllSensors,
  loadBlockList,
  loadSensorStatus,
} = actions;

const {
  selectSensorsForRanchId,
  selectRanchById,
  selectBlocksForRanchId,
} = selectors;

export const MapByRanchId = ({ match }) => {
  const [presentationMode, setPresentationMode] = useState('connectivity');
  const [showLabels, setShowLabels] = useState(true);
  const [listDataState, setListDataState] = useState({});

  const { t } = useTranslation();
  const containerRef = useRef(null);

  const dispatch = useDispatch();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [viewType, setViewType] = useState('map');

  const [selectedSensor, setSelectedSensor] = useState(undefined);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [ranchesGeoJSONState, setRanchesGeoJSONState] = useState(undefined);
  const [blocksGeoJSONState, setBlocksGeoJSONState] = useState(undefined);
  const [sensorsGeoJSONState, setSensorsGeoJSONState] = useState(undefined);
  const [sensorsAllRawState, setSensorsAllRawState] = useState(undefined);
  const [sensorsStatusGeoJSONState, setSensorsStatusGeoJSONState] = useState(undefined);

  const ranchId = Number(match.params.ranch_id);

  const selRanchById = useSelector(
    (state) => selectRanchById(state, ranchId),
  );

  const selBlocksByRanchId = useSelector(
    (state) => selectBlocksForRanchId(state, ranchId),
  );

  const selSensorsByRanchId = useSelector(
    (state) => selectSensorsForRanchId(state, ranchId),
  );

  const selSensorsDataStatus = useSelector(
    (state) => state.sensorsData.status,
  );

  useEffect(() => {
    dispatch(loadRanchDetail(ranchId));
    dispatch(loadAllSensors());
    dispatch(loadBlockList());
  }, [ranchId, dispatch]);

  useEffect(() => {
    if (!selSensorsByRanchId) {
      return;
    }

    if (selSensorsByRanchId.loading) {
      return;
    }

    if (isEqual(selSensorsByRanchId, sensorsAllRawState)) {
      return;
    }

    const sensorsGeoJSON = {
      type: 'FeatureCollection',
      features: selSensorsByRanchId
        .filter((s) => s.visible)
        .map((s) => ({
          id: s.id,
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [s.location.lng, s.location.lat],
          },
          properties: s,
        })),
    };

    prepareZeroIslandSensors(sensorsGeoJSON);

    if (!isEqual(sensorsGeoJSONState, sensorsGeoJSON)) {
      setSensorsAllRawState(selSensorsByRanchId);
      setSensorsGeoJSONState(sensorsGeoJSON);
      sensorsGeoJSON.features
        .forEach((feature) => {
          const { identifier, type } = feature.properties;
          dispatch(loadSensorStatus({ identifier, type }));
        });
    }
  }, [selSensorsByRanchId, sensorsGeoJSONState, dispatch, sensorsAllRawState]);

  useEffect(() => {
    if (sensorsGeoJSONState && !selSensorsDataStatus.loading
      && sensorsGeoJSONState.features.length === selSensorsDataStatus.ids.length) {
      let entitiesLoaded = true;
      for (let i = 0; i < selSensorsDataStatus.ids.length; i++) {
        if (selSensorsDataStatus.entities[selSensorsDataStatus.ids[i]].loading) {
          entitiesLoaded = false;
          break;
        }
      }
      if (entitiesLoaded) {
        const now = (new Date()).getTime();
        const featuresWithStatus = sensorsGeoJSONState.features
          .filter((feature) => sensorTypeAllowedForMode(presentationMode, feature.properties.type))
          .map((feature) => {
            const f = cloneDeep(feature);
            const id = `${feature.properties.type}/${feature.properties.identifier}`;

            let lastPostedInMinutes = -1;
            if (selSensorsDataStatus.entities[id].earliestDate
              && selSensorsDataStatus.entities[id].latestDate) {
              const earliestDate = (new Date(selSensorsDataStatus.entities[id].earliestDate))
                .getTime();
              const lastPosted = (new Date(selSensorsDataStatus.entities[id].latestDate))
                .getTime();
              if (lastPosted >= earliestDate) {
                lastPostedInMinutes = Math.round((now - lastPosted) / 1000 / 60);
              }
            }

            f.properties = {
              ...cloneDeep(selSensorsDataStatus.entities[id]),
              ...cloneDeep(feature.properties),
              lastPostedInMinutes,
            };
            return f;
          });

        const sensorsWithStateGeoJSON = {
          type: 'FeatureCollection',
          features: featuresWithStatus,
        };

        if (!isEqual(sensorsWithStateGeoJSON, sensorsStatusGeoJSONState)) {
          setSensorsStatusGeoJSONState(sensorsWithStateGeoJSON);
        }
      }
    }
  }, [selSensorsDataStatus, sensorsGeoJSONState, sensorsStatusGeoJSONState, presentationMode]);

  useEffect(() => {
    if (sensorsGeoJSONState && !selSensorsDataStatus.loading
      && sensorsGeoJSONState.features.length === selSensorsDataStatus.ids.length) {
      let entitiesLoaded = true;
      for (let i = 0; i < selSensorsDataStatus.ids.length; i++) {
        if (selSensorsDataStatus.entities[selSensorsDataStatus.ids[i]].loading) {
          entitiesLoaded = false;
          break;
        }
      }
      if (entitiesLoaded) {
        const now = (new Date()).getTime();

        const listData = {};

        sensorsGeoJSONState.features
          .filter((feature) => feature.properties.type !== 'cavalier')
          .forEach((feature) => {
            // const f = cloneDeep(feature);
            const id = `${feature.properties.type}/${feature.properties.identifier}`;

            let lastPostedInMinutes = -1;
            if (selSensorsDataStatus.entities[id].earliestDate
              && selSensorsDataStatus.entities[id].latestDate) {
              const earliestDate = (new Date(selSensorsDataStatus.entities[id].earliestDate))
                .getTime();
              const lastPosted = (new Date(selSensorsDataStatus.entities[id].latestDate))
                .getTime();
              if (lastPosted >= earliestDate) {
                lastPostedInMinutes = Math.round((now - lastPosted) / 1000 / 60);
              }
            }

            const properties = {
              ...cloneDeep(selSensorsDataStatus.entities[id]),
              ...cloneDeep(feature.properties),
              lastPostedInMinutes,
              id,
            };
            if (!listData[properties.type]) {
              listData[properties.type] = [];
            }
            listData[properties.type].push(properties);
          });

        if (!isEqual(listData, listDataState)) {
          setListDataState(listData);
          setIsDataLoading(false);
        }
      }
    }
  }, [selSensorsDataStatus, sensorsGeoJSONState, listDataState]);

  useEffect(() => {
    if (selRanchById && !selRanchById.loading) {
      const ranchesGeoJSON = {
        type: 'FeatureCollection',
        features: [{
          id: `${selRanchById.id}`,
          type: 'Feature',
          geometry: selRanchById.bounds,
          properties: selRanchById,
        }],
      };
      if (JSON.stringify(ranchesGeoJSONState) !== JSON.stringify(ranchesGeoJSON)) {
        setRanchesGeoJSONState(ranchesGeoJSON);
      }
    }
  }, [selRanchById, ranchesGeoJSONState]);

  useEffect(() => {
    if (selBlocksByRanchId && !selBlocksByRanchId.loading && selBlocksByRanchId instanceof Array) {
      const blocksGeoJSON = {
        type: 'FeatureCollection',
        features: selBlocksByRanchId.map((b) => ({
          id: `${b.id}`,
          type: 'Feature',
          geometry: b.bounds,
          properties: b,
        })),
      };
      if (JSON.stringify(blocksGeoJSONState) !== JSON.stringify(blocksGeoJSON)) {
        setBlocksGeoJSONState(blocksGeoJSON);
      }
    }
  }, [selBlocksByRanchId, blocksGeoJSONState]);

  const padding = {
    top: 30,
    left: 30,
    bottom: 30,
    right: 130,
  };
  if (isMobile) {
    padding.top = 10;
    padding.left = 10;
    padding.bottom = 10;
    padding.right = 10;
  }
  if (isDrawerVisible && isMobile) {
    padding.bottom = 300 + 10;
  }
  if (isDrawerVisible && !isMobile) {
    padding.right = 400 + 30;
  }

  const sensor = selectedSensor || { properties: {} };


  const isPortrait = window.innerWidth < window.innerHeight;

  return (
    <div className="sensors-by-ranch-id-page">
      <div className="list-switch-package">
        <Button
          type={viewType === 'map' ? 'primary' : 'secondary'}
          value="map"
          icon={<FaMapMarkedAlt style={{ marginTop: '4px' }} />}
          size="large"
          data-test-id="switch-to-mapview"
          onClick={() => {
            if (viewType !== 'map') {
              setViewType('map');
            }
          }}
        />
        <Button
          type={viewType === 'list' ? 'primary' : 'secondary'}
          value="list"
          icon={<FaList style={{ marginTop: '4px' }} />}
          size="large"
          data-test-id="switch-to-listview"
          onClick={() => {
            if (viewType !== 'list') {
              setViewType('list');
            }
          }}
        />
      </div>

      {viewType === 'list' && (
        <ListByRanchId listData={listDataState} isDataLoading={isDataLoading} />
      )}

      {viewType === 'map' && (
        <div className="map-by-ranch-id-page">
          <div ref={containerRef} className={`map-by-ranch-id-page${isMobile ? ' map-by-ranch-id-page-mobile' : ''}`}>

            <div className="top-controls-package">
              <Dropdown
                trigger={['click']}
                className="gear-dropdown-menu"
                overlay={() => (
                  <MapSettingsMenu
                    visible
                    showLabels={showLabels}
                    renderItemShowLabels={presentationMode === 'waterpressure'}
                    renderItemPresentationMode
                    toggleShowLabels={() => {
                      setShowLabels(!showLabels);
                    }}
                    onChangePresentationMode={(value) => {
                      setIsDrawerVisible(false);
                      setSelectedSensor(undefined);
                      setPresentationMode(value);
                    }}
                  />
                )}
              >
                <Button icon={<SettingOutlined />} type="link" size="large" />
              </Dropdown>
            </div>

            <div className="map-page-map">
              <Map
                selectSensor={(sensorType, sensorIdentifier) => {
                  const s = cloneDeep(sensorsStatusGeoJSONState);
                  for (let i = 0; i < s.features.length; i++) {
                    const {
                      identifier,
                      type,
                    } = s.features[i].properties;
                    if (sensorIdentifier === identifier && sensorType === type) {
                      setSelectedSensor(s.features[i]);
                      setIsDrawerVisible(true);
                      break;
                    }
                  }
                }}
                showPopUpOnMarkerClick={false}
                showAllSensorLabels={presentationMode === 'waterpressure' && showLabels}
                activePopUp={undefined}
                ranchesGeoJSON={ranchesGeoJSONState}
                blocksGeoJSON={blocksGeoJSONState}
                sensorsGeoJSON={sensorsStatusGeoJSONState}
                presentationMode={presentationMode}
                mapBoxPadding={padding}
                selectedSensor={selectedSensor}
                isDataLoading={isDataLoading}
              />
            </div>

            {
              !isMobile && (
                <Drawer
                  className="drawer-desktop"
                  style={{ marginTop: '50px', minWidth: isDrawerVisible ? '300px' : '0px' }}
                  title={t('Item Properties')}
                  placement="right"
                  closable
                  onClose={() => {
                    setIsDrawerVisible(false);
                    setSelectedSensor(undefined);
                  }}
                  visible={isDrawerVisible}
                  key="right"
                  mask={false}
                >
                  <Descriptions column={1}>
                    {renderOptionalDescription('Name', sensor.properties.name, true)}
                    {renderOptionalDescription('Sensor Type', sensor.properties.type)}
                    {renderOptionalDescription('Identifier', sensor.properties.identifier)}
                    <Descriptions.Item label="Last Post" className="last-posted">
                      {sensor.properties.latestDate
                        ? <Moment fromNow ago>{sensor.properties.latestDate}</Moment> : 'None'}
                    </Descriptions.Item>
                    {presentationMode === 'waterpressure' && renderOptionalDescription('PSI', prepareNumberForDisplay(sensor.properties.psi))}
                  </Descriptions>
                </Drawer>
              )
            }

            {
              isMobile && (
                <Drawer
                  // eslint-disable-next-line no-nested-ternary
                  style={{ minHeight: isDrawerVisible ? isPortrait ? '250px' : '200px' : '0px' }}
                  title={t('Item Properties')}
                  placement="bottom"
                  closable
                  onClose={() => {
                    setIsDrawerVisible(false);
                    setSelectedSensor(undefined);
                  }}
                  visible={isDrawerVisible}
                  key={`bottom${isPortrait}`}
                  mask={false}
                >
                  <Descriptions column={1}>
                    {renderOptionalDescription('Name', sensor.properties.name, true)}
                    {renderOptionalDescription('Sensor Type', sensor.properties.type)}
                    {renderOptionalDescription('Identifier', sensor.properties.identifier)}
                    <Descriptions.Item label="Last Post" className="last-posted">
                      {sensor.properties.latestDate
                        ? <Moment fromNow ago>{sensor.properties.latestDate}</Moment> : 'None'}
                    </Descriptions.Item>
                    {presentationMode === 'waterpressure' && renderOptionalDescription('PSI', prepareNumberForDisplay(sensor.properties.psi))}
                  </Descriptions>
                </Drawer>
              )
            }
          </div>
        </div>
      )}
    </div>
  );
};

MapByRanchId.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      ranch_id: PropTypes.string,
    }),
  }).isRequired,
};
