import { loadBlockList } from './blocks';
import { loadRanchList, loadRanchDetail } from './ranches';
import { loadEntityList, loadEntityDetail } from './entities';
import {
  loadPlansByRanchId,
  loadPlanById,
  patchPlan,
  createPlan,
  updatePlan,
} from './plans';
import { loadSensorStatus } from '../sensor/actions';
import { selectBlockById } from './selectors';

const loadFarmData = () => (dispatch) => {
  dispatch(loadBlockList());
  dispatch(loadRanchList());
  dispatch(loadEntityList());
};

const loadBlockControlStatus = (blockId) => (dispatch, getState) => {
  const block = selectBlockById(getState(), blockId);
  if (block && block.valve) {
    dispatch(loadSensorStatus(block.valve));
  }
  if (block && block.vfd) {
    dispatch(loadSensorStatus(block.vfd));
  }
};

export {
  loadBlockList,
  loadRanchList,
  loadEntityList,
  loadEntityDetail,
  loadRanchDetail,
  loadFarmData,
  loadPlansByRanchId,
  loadPlanById,
  patchPlan,
  createPlan,
  updatePlan,
  loadBlockControlStatus,
};
