import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { controlApi } from 'farmx-api';
import {
  initialState,
  requestItemById,
  receiveItem,
  requestItemByIdFailed,
  loadDetail,
} from '../../helpers';

const {
  fetchBlockSchedule,
} = controlApi;

export const scheduleAdapter = createEntityAdapter();

const name = 'schedule';

export const loadBlockSchedule = createAsyncThunk(
  `${name}/loadBlockSchedule`,
  loadDetail({
    getData: fetchBlockSchedule,
    getRequestState: (id, state) => state.controlData.schedule.entities[id],
  }),
);

const scheduleSlice = createSlice({
  name,
  initialState: scheduleAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [loadBlockSchedule.pending]: requestItemById(scheduleAdapter),
    [loadBlockSchedule.fulfilled]: receiveItem(scheduleAdapter),
    [loadBlockSchedule.rejected]: requestItemByIdFailed(scheduleAdapter),
  },
});

export default scheduleSlice.reducer;
