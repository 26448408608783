/* eslint-disable no-plusplus */
/* eslint-disable prefer-template */
import L from 'leaflet';
import { mapPresentationModes } from './constants';

export const prepareMarkerClusterIcon = (presentationMode) => (cluster) => {
  const childCount = cluster.getChildCount();

  let c = ' marker-cluster-';
  if (childCount < 10) {
    c += 'small';
  } else if (childCount < 100) {
    c += 'medium';
  } else {
    c += 'large';
  }

  const markers = cluster.getAllChildMarkers();

  if (presentationMode === 'connectivity') {
    c = ' marker-cluster-unknown';
    let minutes = 0;
    for (let i = 0; i < markers.length; i++) {
      const marker = markers[i];
      const lastPosted = marker.feature.properties.lastPostedInMinutes;
      if (lastPosted === undefined || lastPosted === -1) {
        minutes = undefined;
        break;
      }
      if (lastPosted > minutes) {
        minutes = lastPosted;
      }
    }

    if (minutes === undefined) {
      c = ' marker-cluster-unknown';
    } else if (minutes <= 30) {
      c = ' marker-cluster-good';
    } else if (minutes > 30 && minutes <= 120) {
      c = ' marker-cluster-warning';
    } else if (minutes > 120) {
      c = ' marker-cluster-bad';
    } else if (minutes === -1) {
      c = ' marker-cluster-offline';
    }
  } else if (presentationMode === 'waterpressure') {
    for (let i = 0; i < markers.length; i++) {
      c = ' marker-cluster-unknown';
      const marker = markers[i];
      const { psi } = marker.feature.properties;
      if (psi > mapPresentationModes.waterpressure.psiLevel) {
        c = ' marker-cluster-over';
        break;
      }
    }
  } else if (presentationMode === 'provision') {
    for (let i = 0; i < markers.length; i++) {
      c = ' marker-cluster-good';
      const marker = markers[i];
      const { provisioned } = marker.feature.properties;
      if (!provisioned) {
        c = ' marker-cluster-bad';
        break;
      }
    }
  }

  return new L.DivIcon({
    html: '<div><span>' + markers.length + '</span></div>',
    className: 'marker-cluster' + c,
    iconSize: new L.Point(40, 40),
  });
};
