import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import styles from './SensorSelect.less';

const DropdownModal = ({
  title,
  displayOptions,
  mobileScreen,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div
        className={styles.flexContainer}
        style={{ height: mobileScreen ? `${window.innerHeight - 60}px` : 'auto' }}
      >
        {mobileScreen
          ? (
            <div className={styles.modalSelectHeader}>
              <div className={styles.modalSelectHeaderText}>
                {t(title)}
              </div>
              <div />
            </div>
          ) : null}
        <div className={styles.sensorOptionDiv}>
          {displayOptions}
        </div>
      </div>
      {mobileScreen
        ? (
          <div className={styles.buttonCentered}>
            <Button id="goBack" type="primary" onClick={onClick} block>
              {t('done')}
            </Button>
          </div>
        ) : null }
    </div>
  );
};

DropdownModal.propTypes = {
  title: PropTypes.string,
  displayOptions: PropTypes.shape({}),
  mobileScreen: PropTypes.bool,
  onClick: PropTypes.func,
};

DropdownModal.defaultProps = {
  title: null,
  displayOptions: null,
  mobileScreen: false,
  onClick: null,
};

export default DropdownModal;
