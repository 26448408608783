import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions, selectors, hooks } from 'farmx-redux-core';
import PropTypes from 'prop-types';
import moment from 'moment';
import HighchartsSchedule from './HighchartsSchedule';

const {
  selectAllBlockSchedule,
  selectBlockControlStatus,
  selectBlockControlStatusLoading,
} = selectors;

const {
  loadBlockSchedule,
  loadBlockControlStatus,
} = actions;

const {
  useBlockNames,
} = hooks;

const IrrigationSchedule = ({
  blocks,
  dates,
  irrigationTypes,
  onDataRetrieval,
  onClickChart,
  callApi,
}) => {
  const apiCalled = useRef(false); // To avoid multiple API calls
  const dispatch = useDispatch();
  useEffect(() => {
    async function loadData() {
      blocks.forEach((d) => {
        dispatch(loadBlockSchedule(d));
        dispatch(loadBlockControlStatus(d));
      });
    }
    if ((callApi || !apiCalled.current) && blocks.length > 0) {
      apiCalled.current = true;
      if (callApi) onDataRetrieval();
      loadData();
    }
  }, [blocks, dispatch, callApi, onDataRetrieval]);

  const blockScheduleData = useSelector(selectAllBlockSchedule).filter(
    (bSch) => blocks.some((d) => d === bSch.id),
  );
  const loading = blockScheduleData.reduce((a, p) => !p || a || p.loading, false);
  const blockNames = useBlockNames(blocks);
  const blockControlStatus = useSelector((state) => selectBlockControlStatus(state, blocks));
  const blockControlStatusLoading = useSelector((state) => (
    selectBlockControlStatusLoading(state, blocks)));

  const jsonKeys = {
    recommended: 'recommended_events',
    past: 'past_events',
    scheduled: 'scheduled_events',
  };

  function getTotalRuntime(scheduleEvents, dateRange) {
    const [dateStart, dateEnd] = dateRange;
    const totalTime = moment.duration();
    scheduleEvents.forEach((event) => {
      const eventStart = moment(event.start_date);
      const eventEnd = moment(event.stop_date);

      if ((eventStart <= dateEnd && eventStart >= dateStart)
        || (eventEnd >= dateStart && eventEnd <= dateEnd)) {
        const start = eventStart > dateStart ? eventStart : dateStart;
        const end = eventEnd < dateEnd ? eventEnd : dateEnd;

        totalTime.add(moment.duration(end.diff(start)));
      }
    });
    return totalTime;
  }

  // for each object in blockScheduleData
  let filteredData = [];
  if (!loading && !blockControlStatusLoading) {
    filteredData = blockScheduleData.map((blockSchedule) => {
      let data = {};
      if (blockSchedule) {
        data = { ...blockSchedule };
        // set the block name by checking proper id
        data.name = blockNames[data.id];
        data.blockControlStatus = blockControlStatus[data.id];
        data.selectedIrrigationTypes = irrigationTypes;
        data.totalRuntimePast = getTotalRuntime(blockSchedule.past_events, dates);
        data.totalRuntimeScheduled = getTotalRuntime(blockSchedule.scheduled_events, dates);
        data.totalRuntimeRecommended = getTotalRuntime(blockSchedule.recommended_events, dates);

        // rest will be set to empty array
        Object.keys(jsonKeys).forEach((key) => {
          if (!irrigationTypes.includes(key)) {
            data[jsonKeys[key]] = [];
            data.selectedIrrigationTypes = irrigationTypes;
          }
        });
        onDataRetrieval({ data_retrieved: data });
      }
      return data;
    });
  }
  const names = filteredData.map((d) => d.name).filter((d) => d !== undefined);

  return (
    <HighchartsSchedule
      chartData={filteredData}
      dates={dates}
      configChange={names.length ? loading : false}
      onClickChart={onClickChart}
    />
  );
};

IrrigationSchedule.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.any),
  dates: PropTypes.arrayOf(PropTypes.object),
  irrigationTypes: PropTypes.arrayOf(PropTypes.any),
  onDataRetrieval: PropTypes.func,
  onClickChart: PropTypes.func,
  callApi: PropTypes.bool,
};

IrrigationSchedule.defaultProps = {
  blocks: null,
  dates: null,
  irrigationTypes: null,
  onDataRetrieval: () => null,
  onClickChart: null,
  callApi: false,
};

export default IrrigationSchedule;
