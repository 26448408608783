import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { sensorApi } from 'farmx-api';

import {
  initialState,
  requestItemByParams,
  receiveItem,
  requestItemByParamsFailed,
  loadByTypeIdentifier,
} from '../../helpers';

import {
  sensorsAdapter,
  selectSensorStatus,
} from '../selectors';

const name = 'status';

export const loadSensorStatus = createAsyncThunk(
  `${name}/loadSingle`,
  loadByTypeIdentifier({
    getData: sensorApi.loadSensorStatus,
    getRequestState: selectSensorStatus,
  }),
);

const status = createSlice({
  name,
  initialState: sensorsAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: {
    [loadSensorStatus.pending]: requestItemByParams(sensorsAdapter),
    [loadSensorStatus.fulfilled]: receiveItem(sensorsAdapter),
    [loadSensorStatus.rejected]: requestItemByParamsFailed(sensorsAdapter),
  },
});

export default status.reducer;
