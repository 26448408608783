import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { constants } from 'farmx-api';
import { PlusOutlined, CloseOutlined, FilterOutlined } from '@ant-design/icons';
import RanchSelect from '../RanchSelect';
import BlockSelect from '../BlockSelect';
import EntitySelect from '../EntitySelect';
import InstallStateSelect from './InstallStateSelect';
import SensorTypeSelect from './SensorTypeSelect';
import styles from './SensorSelect.less';
import DropdownModal from './DropdownModal';
import commonstyles from '../CommonStyle.less';

export const OP = { OR: 'OR', AND: 'AND' }; // FILTER OPERATIONS

export const INSTALL_STATE = {
  unknown: 'unknown',
  inventory: 'inventory',
  planned: 'planned',
  installed: 'installed',
  removed: 'removed',
  withdrawn: 'withdrawn',
  maintenance: 'maintenance',
  dead: 'dead',
};

const FilterListItem = ({
  onAdd,
  onChange,
  changeIndex,
  onRemove,
  type,
  typeFilter, // planning to use this to put constraint on sensor select types
  value,
  op,
  noEdit,
  noDelete,
}) => {
  const [selectType, setSelectType] = useState(null);
  const [selectVal, setSelectVal] = useState(null);
  const [opVal, setOpVal] = useState(OP.AND);
  const { t } = useTranslation();
  const [modal, setModalState] = useState(false);
  const mobileScreen = window.innerWidth < 599;

  const onSensorTypeOrStateSelect = (val) => {
    setSelectVal(val);
    if (onChange) {
      onChange({
        index: changeIndex, type: selectType, value: val, op: opVal,
      });
    }
  };

  const getSensorTypes = () => {
    const allTypes = Object.entries(constants.SENSOR_TYPES);
    if (typeFilter) return allTypes.filter((sType) => typeFilter.indexOf(sType[0]) >= 0);
    return allTypes;
  };

  const sensorTypes = getSensorTypes();
  let selectedType;
  if (sensorTypes.length === 1) {
    const selType = sensorTypes[0][0];
    selectedType = selType;
  } else if (value) selectedType = value;
  else if (selectVal) selectedType = selectVal;

  const installStates = Object.values(INSTALL_STATE);

  let selectedState;
  if (installStates.length === 1) {
    const selType = installStates[0];
    selectedState = selType;
  } else if (value) selectedState = value;
  else if (selectVal) selectedState = selectVal;

  useEffect(() => {
    if (type) setSelectType(type);
    if (value) setSelectVal(value);
    if (op) setOpVal(op);
  }, [type, value, op]);

  const onSelectType = (d) => {
    setSelectType(d);
    if (changeIndex >= 0) {
      setSelectVal(undefined);
      onChange({
        index: changeIndex, type: d, value: undefined, op: opVal,
      });
    }
    if (!mobileScreen) setModalState(false);
  };

  const onSelectVal = (val) => {
    setSelectVal(val);
  };

  const onClickIcon = () => {
    setSelectType(undefined);
    setSelectVal(undefined);
    setOpVal(OP.AND);
    if (!value && !type) {
      if (selectType && selectVal) onAdd({ type: selectType, value: selectVal, op: opVal });
    } else onRemove({ index: changeIndex });
  };

  const onChangeType = (d) => {
    if (value) {
      if (changeIndex >= 0) setSelectVal(undefined);
      onChange({
        index: changeIndex, type: d, value: changeIndex >= 0 ? undefined : selectVal, op: opVal,
      });
    }
  };

  const onChangeVal = (val) => {
    onChange({
      index: changeIndex, type: selectType, value: val, op: opVal,
    });
  };

  const onChangeOp = (e) => {
    let opValue = OP.AND;
    if (e.target.textContent === OP.AND) {
      opValue = OP.OR;
      setOpVal(OP.OR);
    } else setOpVal(OP.AND);
    onChange({
      index: changeIndex, type: selectType, value: selectVal, op: opValue,
    });
  };

  const displayOp = () => (
    <div data-testid={`${opVal || op}_${value || selectVal}`}>
      {op && (
      <Button
        disabled={noEdit}
        className={styles.optionOrButton}
        onClick={onChangeOp}
      >
        {opVal || op}
      </Button>
      )}
    </div>
  );

  const filterbuttonstyle = {
    flex: 'none',
    display: 'block',
    color: type ? 'red' : '#40a9ff',
  };

  const buttonstyle = {
    flex: 'none',
    padding: '8px',
    display: 'block',
    cursor: 'pointer',
    color: type ? 'red' : '#40a9ff',
  };

  const addbuttonstyle = {
    borderWidth: '2px',
    fontSize: '12px',
    borderRadius: '6px',
    borderStyle: 'solid',
    height: 'auto',
    float: 'right',
    color: 'red',
  };

  const selectFilter = {
    background: type ? 'darkgray' : 'lightgray',
  };


  function getComponentForType(selectedFilterType) {
    switch (selectedFilterType) {
      case 'ranch':
        return (
          <RanchSelect
            onSelect={onSelectVal}
            onChange={onChangeVal}
            defaultOption={[value]}
            placeholder="Select Ranch"
          />
        );
      case 'block':
        return (
          <BlockSelect
            onSelect={onSelectVal}
            onChange={onChangeVal}
            defaultOption={[value]}
            placeholder="Select Block"
          />
        );
      case 'entity':
        return (
          <EntitySelect
            onSelect={onSelectVal}
            onChange={onChangeVal}
            defaultOption={[value]}
            placeholder="Select Entity"
          />
        );
      case 'install_state':
        return (
          <InstallStateSelect
            onChange={onSensorTypeOrStateSelect}
            selectedState={selectedState}
            installStates={installStates}
          />
        );
      case 'type':
        return (
          <SensorTypeSelect
            disabled={noEdit}
            onChange={onSensorTypeOrStateSelect}
            selectedType={selectedType}
            sensorTypes={sensorTypes}
          />
        );
      default:
        return <Select style={{ width: '100%' }} options={[]} placeholder="--Select--" />;
    }
  }

  return (
    <div>
      <div className={styles.selectFilter} style={selectFilter}>
        <table className={styles.filterOptions}>
          <tbody>
            <tr>
              <td>
                <FilterOutlined className={styles.filterIcon} style={filterbuttonstyle} />
              </td>
              <td>
                <Select
                  disabled={noEdit}
                  className={styles.selectFilterDropdown}
                  onSelect={onSelectType}
                  onChange={onChangeType}
                  placeholder={t('Select type')}
                  defaultValue={type || selectType}
                  value={type || selectType}
                  dropdownStyle={{ minWidth: '100px' }}
                  options={[{ label: 'ranch', value: 'ranch' }, { label: 'block', value: 'block' },
                    { label: 'entity', value: 'entity' },
                    { label: 'install state', value: 'install_state' },
                    { label: 'sensor type', value: 'type' }]}
                  onFocus={() => setModalState(true)}
                  open={mobileScreen ? modal : undefined}
                  dropdownClassName={commonstyles.selectDropDown}
                  dropdownRender={mobileScreen ? (menu) => (
                    <DropdownModal
                      title="Select Type"
                      displayOptions={menu}
                      mobileScreen={mobileScreen}
                      onClick={() => setModalState(false)}
                    />
                  ) : undefined}
                />
              </td>
              <td>
                <div className={styles.itemSelect}>
                  {getComponentForType(selectType)}
                </div>
              </td>
              <td>
                <div className={styles.optionButton}>
                  {displayOp()}
                </div>
              </td>
              <td>
                <div className={styles.beforeAddButton}>
                  {value || type
                    ? !noDelete
                            && (
                            <div className={styles.closebuttonstyle} style={addbuttonstyle}>
                              <CloseOutlined
                                style={buttonstyle}
                                onClick={onClickIcon}
                                data-testid={`${value || selectVal}_CloseIcon`}
                              />
                            </div>
                            )
                    : (
                      <div className={styles.addbuttonstyle}>
                        <PlusOutlined style={buttonstyle} onClick={onClickIcon} />
                      </div>
                    )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

FilterListItem.propTypes = {
  onAdd: PropTypes.func,
  onChange: PropTypes.func,
  changeIndex: PropTypes.number,
  onRemove: PropTypes.func,
  type: PropTypes.string,
  typeFilter: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  op: PropTypes.string,
  noEdit: PropTypes.bool,
  noDelete: PropTypes.bool,
};

FilterListItem.defaultProps = {
  onAdd: null,
  onChange: null,
  changeIndex: null,
  onRemove: null,
  type: null,
  typeFilter: null,
  value: null,
  op: null,
  noEdit: null,
  noDelete: null,
};

export default FilterListItem;
