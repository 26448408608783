import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import transform from './transforms';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['clientData'],
  transforms: [transform],
};


const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  // https://redux-toolkit.js.org/api/getDefaultMiddleware#customizing-the-included-middleware
  // map page becomes unresponsive to develop without excluding those two
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
});

const persistor = persistStore(store);

export {
  store,
  persistor,
};
