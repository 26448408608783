import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getSelectedRanch, getUrlLoad, getSelectedBlocks } from '../selectors';
import { setBlocks, setRanch, setUrlLoad } from '../actions';

// Set URL param from state
export function useSetURLFromState(urlSearch, search, pathname, cObj) {
  /**
   * Funtion to create param string
   * @param {*} blocks - Input blocks from state
   * @returns param string
   */
  function createParamStr(blocks) {
    const startDate = cObj.dateRange.length ? cObj.dateRange[0] : null;
    const endDate = cObj.dateRange.length ? cObj.dateRange[1] : null;
    const params = {};

    // create sensor params
    if (blocks && blocks.length) {
      params.blockId = blocks.join(',');
    }

    if (cObj.irrigationTypes) params.irrigationTypes = cObj.irrigationTypes.join(',');
    if (startDate) params.startDate = startDate.toISOString();
    if (endDate) params.endDate = endDate.toISOString();
    if (cObj.radioOption) params.radioOption = cObj.radioOption;

    let paramStr = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
    if (paramStr.length) paramStr = `?${paramStr}`;
    return paramStr;
  }
  const ranchId = useSelector(getSelectedRanch).payload;
  const blockIds = useSelector(getSelectedBlocks).payload || [];
  const history = useHistory();
  let paramStr;
  if (cObj) {
    paramStr = createParamStr(blockIds);
  }
  useEffect(() => {
    if (ranchId && ranchId.length && (pathname.includes('control')
    || pathname.includes('dashboard')) && (!urlSearch.current.includes(ranchId[0])
    || !search.includes(ranchId[0]))) {
      history.push({ search: `?ranchId=${ranchId[0]}` });
      urlSearch.current = `?ranchId=${ranchId[0]}`;
    }

    // Handle the schedule page URL update logic
    if (blockIds && cObj && pathname.includes('schedule') && !urlSearch.current.includes('ranchId')
    && (!urlSearch.current || search !== paramStr)) {
      history.replace({ pathname, search: paramStr });
      urlSearch.current = paramStr;
    }
  }, [blockIds, cObj, history, paramStr, pathname, ranchId, search, urlSearch]);
}

// Reading URL param and set to state
export function useSetStateFromURL(urlSearch) {
  const ranchId = useSelector(getSelectedRanch).payload || [];
  const blockIds = useSelector(getSelectedBlocks).payload || [];
  const urlLoad = useSelector(getUrlLoad).payload;
  const dispatch = useDispatch();
  if (urlSearch.current) {
    const params = new URLSearchParams(urlSearch.current);
    const ranchIdParam = params.get('ranchId');
    const blockIdParam = params.get('blockId');
    let blockIdsFromURL;
    if (blockIdParam) {
      blockIdsFromURL = blockIdParam.split(',').filter((d) => d.length > 0).map((d) => Number(d));
    }
    if (urlLoad && ranchIdParam && ranchId && Number(ranchIdParam)
      && !urlSearch.current.includes(ranchId[0]) && !urlSearch.current.includes('blockId')) {
      dispatch(setRanch(Number(ranchIdParam)));
      dispatch(setUrlLoad(false));
      return true;
    }
    if (urlLoad && (blockIdsFromURL || (blockIds && blockIds.length))
       && !urlSearch.current.includes(`blockId=${blockIds.join(',')}&`)
      && !urlSearch.current.includes('ranchId')) {
      dispatch(setBlocks(blockIdsFromURL));
      dispatch(setUrlLoad(false));
      return true;
    }
  }
  return false;
}
