import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import { useLeaflet } from 'react-leaflet';
import { leafletMarkerClusterGroup } from './lib/leaflet-markercluster/leafletMarkercluster';
import { prepareMarker } from './prepareMarker';
import { prepareMarkerClusterIcon } from './prepareMarkerClusterIcon';
import { getMarkerTooltipBuyPresentationMode } from './constants';

leafletMarkerClusterGroup(L);

const shouldSpiderfy = (c) => c.getChildCount() < 7;

export function MarkerCluster({
  sensorsGeoJSON,
  showAllSensorLabels,
  activePopUp,
  selectSensor,
  showPopUpOnMarkerClick,
  presentationMode,
  selectedSensor,
  refreshMap,
}) {
  const marketClusterGroup = L.markerClusterGroup({
    maxClusterRadius: 50,
    unspiderfyOnMapClick: false,
    shouldSpiderfy,
    animate: false,
    iconCreateFunction: prepareMarkerClusterIcon(presentationMode),
    spiderLegPolylineOptions: {
      weight: 1.5, color: '#eee', opacity: 0.8,
    },
    polygonOptions: {
      stroke: false,
      fill: false,
    },
  });

  const { map } = useLeaflet();

  const prevsensorsGeoJSON = useRef(JSON.stringify({}));
  const markers = useRef([]);

  const onMarkerPopupOpen = ({ target: { feature: { properties: { type, identifier } } } }) => {
    selectSensor(type, identifier);
  };

  useEffect(() => {
    const destroy = () => {
      markers.current.forEach((marker) => {
        marker.off('popupopen', onMarkerPopupOpen);
      });
      marketClusterGroup.clearLayers();
    };
    return destroy;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    markers.current.forEach((marker) => {
      if (marker.unbindTooltip) {
        marker.unbindTooltip();
      }
    });

    if (showAllSensorLabels) {
      markers.current.forEach((marker) => {
        marker.bindTooltip(
          getMarkerTooltipBuyPresentationMode(marker.feature, presentationMode),
          {
            direction: 'auto',
            permanent: true,
            className: `${marker.feature.properties.type}-${marker.feature.properties.identifier}`,
          },
        );
      });
    }
  }, [showAllSensorLabels]);

  useEffect(() => {
    if (typeof sensorsGeoJSON === 'undefined') {
      marketClusterGroup.clearLayers();
      markers.current = [];
      return;
    }

    const sensorsGeoJSONString = JSON.stringify(sensorsGeoJSON);
    if (prevsensorsGeoJSON.current !== sensorsGeoJSONString) {
      marketClusterGroup.clearLayers();
      markers.current = [];

      // eslint-disable-next-line no-param-reassign
      sensorsGeoJSON.features = sensorsGeoJSON.features
        .filter((feature) => feature.geometry.coordinates[0] && feature.geometry.coordinates[1]);

      if (!sensorsGeoJSON.features.length) {
        return;
      }
      const geojson = L.geoJSON(sensorsGeoJSON, {
        pointToLayer: (feature, latlng) => {
          const marker = prepareMarker(feature, latlng, presentationMode);
          markers.current.push(marker);

          if (showPopUpOnMarkerClick) {
            const popupContent = `<div class="popup-inner" data-test-id="popup-${feature.id}">
              <div class="popup-title">${feature.properties.type}</div>
              <div>${feature.properties.identifier}</div>
              <div class="popup-location">${feature.geometry.coordinates[1]}, ${feature.geometry.coordinates[0]}</div>
              </div>`;

            marker.bindPopup(popupContent, { autoPan: false });
          }

          if (showAllSensorLabels) {
            marker.bindTooltip(
              getMarkerTooltipBuyPresentationMode(feature, presentationMode),
              {
                direction: 'auto',
                permanent: true,
                className: `${feature.properties.type}-${feature.properties.identifier}`,
              },
            );
          } else if (presentationMode === 'connectivity') {
            marker.bindTooltip(
              feature.properties.name || 'NO NAME',
              {
                direction: 'auto',
                permanent: false,
                className: `${feature.properties.type}-${feature.properties.identifier}`,
              },
            );
          }

          marker.on('click', onMarkerPopupOpen);

          return marker;
        },
      });

      marketClusterGroup.addLayer(geojson);
      map.addLayer(marketClusterGroup);
      map.setZoom(Math.round(map.getZoom()));
      prevsensorsGeoJSON.current = sensorsGeoJSONString;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sensorsGeoJSON]);

  useEffect(() => {
    if (activePopUp === Object(activePopUp) && activePopUp.identifier) {
      const { identifier } = activePopUp;
      const markersLength = markers.current.length;
      for (let i = 0; i < markersLength; i += 1) {
        const marker = markers.current[i];
        if (marker.feature.properties.identifier === identifier) {
          marker.openPopup();
          break;
        }
      }
    }
  }, [activePopUp, map]);

  useEffect(() => {
    markers.current
      .forEach((marker) => {
        const iconOpts = marker.getIcon().options;
        if (selectedSensor
          && marker.feature.properties.identifier === selectedSensor.properties.identifier
          && marker.feature.properties.type === selectedSensor.properties.type) {
          marker.setIcon(L.VectorMarkers.icon({
            ...iconOpts,
            selected: true,
          }));
        } else if (iconOpts.selected) {
          marker.setIcon(L.VectorMarkers.icon({
            ...iconOpts,
            selected: false,
          }));
        }
      });
  }, [selectedSensor]);

  refreshMap();
  return null;
}

MarkerCluster.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  activePopUp: PropTypes.object,
  selectSensor: PropTypes.func,
  showAllSensorLabels: PropTypes.bool,
  sensorsGeoJSON: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        type: PropTypes.string,
        geometry: PropTypes.shape({
          coordinates: PropTypes.arrayOf(PropTypes.number),
          type: PropTypes.string,
        }),
        properties: PropTypes.object,
      }),
    ),
  }),
  showPopUpOnMarkerClick: PropTypes.bool,
  presentationMode: PropTypes.string,
  refreshMap: PropTypes.func.isRequired,
  selectedSensor: PropTypes.any,
};

MarkerCluster.defaultProps = {
  activePopUp: undefined,
  selectSensor: undefined,
  showAllSensorLabels: undefined,
  sensorsGeoJSON: undefined,
  showPopUpOnMarkerClick: true,
  presentationMode: undefined,
  selectedSensor: undefined,
};
