import L from 'leaflet';
import { stateColorCode, icons, mapPresentationModes } from './constants';

/**
 * connectivity status
 * green - reported in last 30 min
 * yellow - reported in last 2 hours
 * red - hasn't reported in last 2 hours
 * grey - hasn't reported ever
 */
function getColorsByLastPostedInMinutes(feature) {
  const minutes = feature.properties.lastPostedInMinutes;
  if (minutes === undefined) {
    return stateColorCode[0];
  }
  if (minutes <= 30) {
    return stateColorCode[5];
  }
  if (minutes > 30 && minutes <= 120) {
    return stateColorCode[3];
  }
  if (minutes > 120) {
    return stateColorCode[2];
  }
  if (minutes === -1) {
    return stateColorCode[1];
  }

  return stateColorCode[1];
}

export function prepareMarker(feature, latlng, presentationMode) {
  const opts = {};
  opts.markerColor = '#fff';
  opts.iconColor = '#000';
  opts.selected = false;

  if (presentationMode === 'connectivity') {
    const color = getColorsByLastPostedInMinutes(feature);
    opts.markerColor = color.markerColor;
    opts.iconColor = 'white';
  }

  if (presentationMode === 'waterpressure') {
    opts.markerColor = 'white';
    opts.iconColor = 'black';
    if (feature.properties.psi > mapPresentationModes.waterpressure.psiLevel) {
      opts.markerColor = '#429aff';
      opts.iconColor = 'white';
    }
  }

  if (presentationMode === 'provision') {
    opts.iconColor = 'white';
    opts.markerColor = '#e23549';
    if (feature.properties.provisioned) {
      opts.markerColor = '#3dc873';
    }
  }

  const markerIcon = L.VectorMarkers.icon({
    icon: icons[feature.properties.type] || 'icon-radio-waves',
    prefix: 'icon',
    markerColor: opts.markerColor,
    iconColor: opts.iconColor,
    dataTestId: feature.id,
    selected: opts.selected,
  });

  // eslint-disable-next-line new-cap
  const marker = new L.marker(latlng, { icon: markerIcon });

  return marker;
}

export function prepareSingleMarkerIcon() {
  const opts = {};
  opts.markerColor = '#fff';
  opts.iconColor = '#000';
  opts.selected = false;

  return L.VectorMarkers.icon({
    icon: 'icon-radio-waves',
    prefix: 'icon',
    markerColor: opts.markerColor,
    iconColor: opts.iconColor,
    dataTestId: 'single-marker',
    selected: opts.selected,
  });
}
