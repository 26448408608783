/* eslint-disable react/jsx-fragments */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable global-require */
import React, {
  useEffect,
  useState,
  useRef,
  Fragment,
} from 'react';
import ReactDOMServer from 'react-dom/server';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import './map.less';
import './lib/leaflet-markercluster/MarkerCluster.less';
import './lib/leaflet-vector-markers/leafletVectorMarkers.less';
import WebMercatorViewport from 'viewport-mercator-project';
import bbox from '@turf/bbox';
import ReactResizeDetector from 'react-resize-detector';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import L from 'leaflet';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Map as LeafletMap,
  TileLayer,
  GeoJSON,
  Marker,
} from 'react-leaflet';
import { MarkerCluster } from './MarkerCluster';
import { leafletVectorMarkers } from './lib/leaflet-vector-markers/leafletVectorMarkers';
import { prepareSingleMarkerIcon } from './prepareMarker';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const isEqual = require('react-fast-compare');

const LabelMarker = ({ position, name, className }) => {
  const icon = L.divIcon({
    className: 'custom-icon',
    html: ReactDOMServer.renderToString(<div className={className}>{name}</div>),
  });

  return (
    <Marker
      position={position}
      icon={icon}
    />
  );
};

leafletVectorMarkers(L);

const accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const initialState = {
  viewBox: {
    latitude: 37.646,
    longitude: -121.016,
    zoom: 6,
  },
  sensorsGeoJSON: {
    features: [],
  },
  ranchesGeoJSON: {
    features: [],
  },
};

function SingleMarker(singleMarkerGeoJSON) {
  return (
    <Marker
      position={[
        singleMarkerGeoJSON.geometry.coordinates[0],
        singleMarkerGeoJSON.geometry.coordinates[1]]}
      icon={prepareSingleMarkerIcon()}
    />
  );
}

function Map({
  children,
  sensorsGeoJSON,
  mapBoxPadding,
  width,
  height,
  activePopUp,
  selectSensor,
  showAllSensorLabels,
  expandedGeoJSON,
  ranchesGeoJSON,
  blocksGeoJSON,
  showPopUpOnMarkerClick,
  presentationMode,
  selectedSensor,
  isDataLoading,
  onClickMap,
  singleMarkerGeoJSON,
  dynamicBoundsGeoJSON,
  onClickDynamicBounds,
}) {
  if (dynamicBoundsGeoJSON && !dynamicBoundsGeoJSON?.features) {
    const dynamicBoundsGeoJSONBuffer = {
      type: 'FeatureCollection',
      features: [],
    };
    dynamicBoundsGeoJSONBuffer.features.push(cloneDeep(dynamicBoundsGeoJSON));
    dynamicBoundsGeoJSON = dynamicBoundsGeoJSONBuffer;
  }

  const [state, setState] = useState(initialState);
  const mounted = useRef(false);
  const mapRef = useRef(null);

  const el = document;
  const event = document.createEvent('HTMLEvents');
  event.initEvent('resize', true, false);
  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });
    mounted.current = true;

    el.dispatchEvent(event);

    return () => {
      console.log('unmount map');
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      return;
    }

    const incomingState = {
      expandedGeoJSON,
      ranchesGeoJSON,
      dynamicBoundsGeoJSON,
      sensorsGeoJSON,
      mapBoxPadding,
      width,
      height,
      viewBox: state.viewBox,
    };

    if (isEqual(incomingState, state)) {
      return;
    }

    if (expandedGeoJSON && expandedGeoJSON.features.length) {
      try {
        if (!width || !height) {
          return;
        }

        const [minLng, minLat, maxLng, maxLat] = bbox(expandedGeoJSON);
        const webMercatorViewport = new WebMercatorViewport({ ...state.viewBox, width, height });

        const { longitude, latitude, zoom } = webMercatorViewport
          .fitBounds([[minLng, minLat], [maxLng, maxLat]], {
            padding: {
              top: mapBoxPadding.top,
              bottom: mapBoxPadding.bottom,
              left: mapBoxPadding.left,
              right: mapBoxPadding.right,
            },
          });

        const newState = {
          ...state,
          expandedGeoJSON,
          ranchesGeoJSON,
          dynamicBoundsGeoJSON,
          sensorsGeoJSON,
          mapBoxPadding,
          viewBox: {
            longitude,
            latitude,
            zoom,
          },
          width,
          height,
        };
        setState(newState);
      } catch (error) {
        console.log('WebMercatorViewport expandedGeoJSON', error);
      }
      return;
    }

    if (!ranchesGeoJSON && !sensorsGeoJSON && singleMarkerGeoJSON) {
      try {
        if (!width || !height) {
          return;
        }

        const newState = {
          ...state,
          expandedGeoJSON,
          ranchesGeoJSON,
          dynamicBoundsGeoJSON,
          sensorsGeoJSON,
          mapBoxPadding,
          singleMarkerGeoJSON,
          viewBox: {
            longitude: singleMarkerGeoJSON.geometry.coordinates[1],
            latitude: singleMarkerGeoJSON.geometry.coordinates[0],
            zoom: 17,
          },
          width,
          height,
        };
        setState(newState);
        return;
      } catch (error) {
        console.log('WebMercatorViewport singleMarkerGeoJSON', error);
      }
    }

    if (dynamicBoundsGeoJSON && dynamicBoundsGeoJSON.features.length) {
      try {
        if (!width || !height) {
          return;
        }

        const [minLng, minLat, maxLng, maxLat] = bbox(dynamicBoundsGeoJSON);
        const webMercatorViewport = new WebMercatorViewport({ ...state.viewBox, width, height });

        const { longitude, latitude, zoom } = webMercatorViewport
          .fitBounds([[minLng, minLat], [maxLng, maxLat]], {
            padding: {
              top: mapBoxPadding.top,
              bottom: mapBoxPadding.bottom,
              left: mapBoxPadding.left,
              right: mapBoxPadding.right,
            },
          });

        const newState = {
          ...state,
          expandedGeoJSON,
          ranchesGeoJSON,
          dynamicBoundsGeoJSON,
          sensorsGeoJSON,
          mapBoxPadding,
          viewBox: {
            longitude,
            latitude,
            zoom,
          },
          width,
          height,
        };
        setState(newState);
      } catch (error) {
        console.log('WebMercatorViewport ranchesGeoJSON', error);
      }
      return;
    }

    if (ranchesGeoJSON && ranchesGeoJSON.features.length) {
      try {
        if (!width || !height) {
          return;
        }

        const [minLng, minLat, maxLng, maxLat] = bbox(ranchesGeoJSON);
        const webMercatorViewport = new WebMercatorViewport({ ...state.viewBox, width, height });

        const { longitude, latitude, zoom } = webMercatorViewport
          .fitBounds([[minLng, minLat], [maxLng, maxLat]], {
            padding: {
              top: mapBoxPadding.top,
              bottom: mapBoxPadding.bottom,
              left: mapBoxPadding.left,
              right: mapBoxPadding.right,
            },
          });

        const newState = {
          ...state,
          expandedGeoJSON,
          ranchesGeoJSON,
          dynamicBoundsGeoJSON,
          sensorsGeoJSON,
          mapBoxPadding,
          viewBox: {
            longitude,
            latitude,
            zoom,
          },
          width,
          height,
        };
        setState(newState);
      } catch (error) {
        console.log('WebMercatorViewport ranchesGeoJSON', error);
      }
      return;
    }

    if (!sensorsGeoJSON) {
      setState({
        ...state,
        expandedGeoJSON,
        ranchesGeoJSON,
        dynamicBoundsGeoJSON,
        sensorsGeoJSON,
        mapBoxPadding,
      });
      return;
    }

    sensorsGeoJSON.features = sensorsGeoJSON.features
      .filter((feature) => feature.geometry.coordinates[0] && feature.geometry.coordinates[1]);

    if (!sensorsGeoJSON.features.length) {
      setState({
        ...state,
        expandedGeoJSON,
        ranchesGeoJSON,
        dynamicBoundsGeoJSON,
        sensorsGeoJSON,
        mapBoxPadding,
      });
      return;
    }

    try {
      if (!width || !height) {
        return;
      }

      const [minLng, minLat, maxLng, maxLat] = bbox(sensorsGeoJSON);
      const webMercatorViewport = new WebMercatorViewport({ ...state.viewBox, width, height });

      const { longitude, latitude, zoom } = webMercatorViewport
        .fitBounds([[minLng, minLat], [maxLng, maxLat]], {
          padding: {
            top: mapBoxPadding.top,
            bottom: mapBoxPadding.bottom,
            left: mapBoxPadding.left,
            right: mapBoxPadding.right,
          },
        });

      const newState = {
        ...state,
        expandedGeoJSON,
        ranchesGeoJSON,
        dynamicBoundsGeoJSON,
        sensorsGeoJSON,
        mapBoxPadding,
        viewBox: {
          longitude,
          latitude,
          zoom,
        },
        width,
        height,
      };
      setState(newState);
    } catch (error) {
      console.log('WebMercatorViewport sensorsGeoJSON', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    expandedGeoJSON,
    singleMarkerGeoJSON,
    ranchesGeoJSON,
    dynamicBoundsGeoJSON,
    sensorsGeoJSON,
    mapBoxPadding,
    width,
    height,
  ]);


  return (
    <Fragment>
      {
        isDataLoading && (
          <div
            style={{
              position: 'absolute',
              zIndex: '9999',
            }}
            className="map-data-is-loading"
          >
            <Spin indicator={antIcon} />
          </div>
        )
      }
      <LeafletMap
        ref={(r) => {
          if (r && r.leafletElement) {
            mapRef.current = r.leafletElement;
          } else {
            mapRef.current = null;
          }
        }}
        doubleClickZoom={false}
        onClick={onClickMap}
        attributionControl={false}
        className="map-leaflet"
        viewport={{
          center: [state.viewBox.latitude, state.viewBox.longitude],
          zoom: state.viewBox.zoom,
        }}
        onViewportChanged={(viewport) => {
          if (viewport && viewport.center) {
            if (
              viewport.center[0] !== state.viewBox.latitude
              || viewport.center[1] !== state.viewBox.longitude
              || state.viewBox.zoom !== viewport.zoom) {
              setState({
                ...state,
                viewBox: {
                  latitude: viewport.center[0],
                  longitude: viewport.center[1],
                  zoom: viewport.zoom,
                },
              });
            }
          }
          try {
            el.dispatchEvent(event);
          } catch (error) {
            console.log('Error map viewport changed', error);
          }
        }}
        zoomControl={false}
      >
        <TileLayer
          url="https://api.mapbox.com/styles/v1/farmx/cinjdgdic0015adnjk1bl7775/tiles/{z}/{x}/{y}?access_token={accessToken}"
          accessToken={accessToken}
          id="farmx.cinjdgdic0015adnjk1bl7775"
          tileSize={512}
          zoomOffset={-1}
          detectRetina
        />

        {Boolean(singleMarkerGeoJSON) && SingleMarker(singleMarkerGeoJSON)}

        {Boolean(state.sensorsGeoJSON) && Boolean(state.sensorsGeoJSON.features.length)
          && (
            <MarkerCluster
              key={JSON.stringify({ presentationMode, s: state.sensorsGeoJSON })}
              sensorsGeoJSON={state.sensorsGeoJSON}
              activePopUp={activePopUp || {}}
              selectSensor={selectSensor}
              showAllSensorLabels={showAllSensorLabels || false}
              showPopUpOnMarkerClick={showPopUpOnMarkerClick}
              presentationMode={presentationMode}
              selectedSensor={selectedSensor}
              refreshMap={() => { el.dispatchEvent(event); }}
            />
          )}

        {mounted.current && Boolean(blocksGeoJSON) && Boolean(blocksGeoJSON.features.length) && (
          blocksGeoJSON.features.map((feature) => {
            const [minLng, minLat, maxLng, maxLat] = bbox(feature);
            const position = [(minLat + maxLat) / 2, (minLng + maxLng) / 2];
            return (
              <GeoJSON
                className={`map-ranch-block-b-${feature.properties.name}`}
                style={{
                  color: '#fff',
                  weight: 1,
                  fillOpacity: 0.4,
                  fillColor: '#fff',
                }}
                key={`${presentationMode}${feature.id}`}
                data={[feature]}
              >
                {
                  mapRef.current && mapRef.current.getZoom() > 13 && (
                    <LabelMarker position={position} name={feature.properties.name} className={`map-ranch-block-i-${feature.properties.name}`} />
                  )
                }
              </GeoJSON>
            );
          })
        )}

        {Boolean(ranchesGeoJSON) && Boolean(ranchesGeoJSON.features.length) && (
          ranchesGeoJSON.features.map((feature) => {
            // space for any conditional style rendering based on properties
            return (
              <GeoJSON
                className={`map-ranch-boundary-${feature.id}`}
                style={{
                  color: '#e23549',
                  weight: 2,
                  fillOpacity: 0,
                  fillColor: '#e23549',
                  dashArray: [10, 10],
                }}
                key={`${JSON.stringify(feature)}`}
                data={[feature]}
              />
            );
          })
        )}

        {Boolean(dynamicBoundsGeoJSON) && Boolean(dynamicBoundsGeoJSON.features.length) && (
          dynamicBoundsGeoJSON.features.map((feature) => (
            <GeoJSON
              className={`map-dynamic-boundary-${feature.id}`}
              style={{
                // color: '#e23549',
                color: '#0000ff',
                weight: 4,
                fillOpacity: 0,
                fillColor: '#e23549',
                dashArray: [10, 10],
              }}
              key={`${JSON.stringify(feature)}`}
              data={[feature]}
              onClick={onClickDynamicBounds}
            />
          ))
        )}

        {children}

      </LeafletMap>
    </Fragment>
  );
}

Map.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  width: PropTypes.number,
  height: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  activePopUp: PropTypes.object,
  selectSensor: PropTypes.func,
  showAllSensorLabels: PropTypes.bool,
  presentationMode: PropTypes.string,
  expandedGeoJSON: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        type: PropTypes.string,
        geometry: PropTypes.shape({
          coordinates: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.array]),
          type: PropTypes.string,
        }),
        properties: PropTypes.object,
      }),
    ),
  }),
  ranchesGeoJSON: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        type: PropTypes.string,
        geometry: PropTypes.shape({
          coordinates: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.array]),
          type: PropTypes.string,
        }),
        properties: PropTypes.object,
      }),
    ),
  }),
  dynamicBoundsGeoJSON: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        type: PropTypes.string,
        geometry: PropTypes.shape({
          coordinates: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.array]),
          type: PropTypes.string,
        }),
        properties: PropTypes.object,
      }),
    ),
  }),
  blocksGeoJSON: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        type: PropTypes.string,
        geometry: PropTypes.shape({
          coordinates: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.array]),
          type: PropTypes.string,
        }),
        properties: PropTypes.object,
      }),
    ),
  }),
  sensorsGeoJSON: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        type: PropTypes.string,
        geometry: PropTypes.shape({
          coordinates: PropTypes.arrayOf(PropTypes.number),
          type: PropTypes.string,
        }),
        properties: PropTypes.object,
      }),
    ),
  }),
  singleMarkerGeoJSON: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    geometry: PropTypes.shape({
      coordinates: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.array]),
      type: PropTypes.string,
    }),
    properties: PropTypes.object,
  }),
  mapBoxPadding: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }),
  showPopUpOnMarkerClick: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  selectedSensor: PropTypes.any,
  isDataLoading: PropTypes.bool,
  onClickMap: PropTypes.func,
  onClickDynamicBounds: PropTypes.func,
};

Map.defaultProps = {
  children: null,
  width: 500,
  height: 500,
  activePopUp: undefined,
  selectSensor: undefined,
  showAllSensorLabels: undefined,
  ranchesGeoJSON: undefined,
  dynamicBoundsGeoJSON: undefined,
  expandedGeoJSON: undefined,
  blocksGeoJSON: undefined,
  sensorsGeoJSON: undefined,
  presentationMode: undefined,
  singleMarkerGeoJSON: undefined,
  mapBoxPadding: {
    top: 100,
    bottom: 100,
    left: 100,
    right: 100,
  },
  showPopUpOnMarkerClick: true,
  selectedSensor: undefined,
  isDataLoading: false,
  onClickMap: undefined,
  onClickDynamicBounds: undefined,
};

export default function ResizeDetector(props) {
  const [dimensions, setDimensions] = useState({ width: 500, height: 500 });
  return (
    <div className="map-wrapper">
      <div className="map-inner-container">
        {/* here refresh rate might be different for desktop vs mobile
      > 500 seems to stop the "screen freeze" in mobile devices */}
        <ReactResizeDetector
          onResize={(width, height) => {
            if (!isEqual(dimensions, { width, height })) {
              setDimensions({ width, height });
            }
          }}
          handleWidth
          handleHeight
          refreshMode="debounce"
          refreshRate={633}
        />
        {/* {({ width, height }) => {
            if (!isEqual(dimensions, { width, height })) {
              console.log('Set dimensions', { width, height });
              setDimensions({ width, height });
            }
            return null;
          }}
        </ReactResizeDetector> */}
        <Map
          // eslint-disable-next-line react/prop-types, react/destructuring-assignment
          key={JSON.stringify(props.sensorsGeoJSON)}
          {...{ ...props }}
          width={Math.round(dimensions.width)}
          height={Math.round(dimensions.height)}
        />
      </div>
    </div>
  );
}
