import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { WarningFilled, LoadingOutlined } from '@ant-design/icons';
import styles from './CommonStyles.less';

export default function PumpStateTag({ loading, state, online }) {
  const color = state === 'on' ? '#1890FF' : '#D40202';
  if (loading) {
    return <LoadingOutlined className={styles.tagIcon} />;
  }
  if (state) {
    if (state !== 'unknown') {
      if (online && state === 'off') {
        return (
          <Tag color="" key={state} className={styles.tagIcon}>
            {state.toUpperCase()}
          </Tag>
        );
      }
      return (
        <Tag color={color} key={state} className={[styles.tagIcon, styles.tagIconIrrigating]}>
          {state.toUpperCase()}
        </Tag>
      );
    }
    return <WarningFilled className={styles.warningIcon} />;
  }
  if (!state && !loading) {
    return <WarningFilled className={styles.warningIcon} />;
  }
  return <LoadingOutlined className={styles.tagIcon} />;
}

PumpStateTag.propTypes = {
  state: PropTypes.string,
  loading: PropTypes.bool,
  online: PropTypes.bool,
};

PumpStateTag.defaultProps = {
  state: null,
  loading: null,
  online: null,
};
