import React from 'react';
import { List, Typography } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectors } from 'farmx-redux-core';
import SensorTypeTag from './SensorTypeTag';
import InstallStateTag from './InstallStateTag';
import styles from './SensorSelect.less';

const { Text } = Typography;
const { selectRanchById, selectBlockById } = selectors;

const SensorOption = ({ sensor }) => {
  const ranch = useSelector((state) => selectRanchById(state, sensor.ranch));
  const block = useSelector((state) => selectBlockById(state, sensor.block));
  return (
    <div id="sensorOption">
      <List
        className={styles.sensorOptions}
      >
        <List.Item className={styles.listSensorOptions}>
          <List.Item.Meta
            className={styles.optionOne}
            title={sensor.name
              ? (
                <Text
                  className={styles.sensorName}
                  ellipsis
                  title={`${sensor.name}`}
                >
                  {`${sensor.name}`}
                </Text>
              )
              : (
                <Text style={{ color: 'lightgray' }} title="No Name">No Name</Text>
              )}
            description={(
              <Text
                className={styles.identifier}
                ellipsis
                title={`${sensor.identifier}`}
              >
                {`${sensor.identifier}`}
              </Text>
                )}
          />
          <List.Item.Meta
            title={(
              <Text
                className={styles.ranchName}
                ellipsis
                title={(ranch && ranch.name) ? ranch.name : ''}
              >
                {(ranch && ranch.name) ? ranch.name : ''}
              </Text>
                )}
            description={(
              <Text
                className={styles.blockName}
                ellipsis
                title={(block && block.name) ? block.name : ''}
              >
                {(block && block.name) ? block.name : ''}
              </Text>
                )}
            className={styles.optionTwo}
          />
          <div
            className={styles.optionThree}
          >
            <InstallStateTag state={sensor.install_state} />
          </div>
          <div
            className={styles.optionFour}
          >
            <SensorTypeTag styles={{ float: 'right' }} sensorType={sensor.type} />
          </div>
        </List.Item>
      </List>
    </div>
  );
};

SensorOption.propTypes = {
  sensor: PropTypes.shape({
    name: PropTypes.string,
    identifier: PropTypes.string,
    ranch: PropTypes.number,
    block: PropTypes.number,
    type: PropTypes.string,
    install_state: PropTypes.string,
  }).isRequired,
};

export default SensorOption;
