import { combineReducers } from 'redux';
import sensors from './sensors';
import status from './status';
import capabilities from './capabilities';
import stats from './stats';
import notes from './notes';
import images from './images';

export default combineReducers({
  sensors,
  status,
  capabilities,
  stats,
  images,
  notes,
});
