import { sensorApi } from 'farmx-api';

import {
  createEntityAdapter,
} from '@reduxjs/toolkit';

import { sensorNotesAdapter } from './reducers/notes';
import { sensorImagesAdapter } from './reducers/images';

import { selectLoadingState } from '../helpers';

const { getSensorKey } = sensorApi;

export const sensorsAdapter = createEntityAdapter({
  selectId: (sensor) => getSensorKey({ type: sensor.type, identifier: sensor.identifier }),
});

// sensor selectors

const selectSensorsState = (state) => state.sensorsData.sensors;
const sensorsSelectors = sensorsAdapter.getSelectors(selectSensorsState);
const selectAllSensors = sensorsSelectors.selectAll;

const selectSensorsForRanchId = (state, ranchId, sensorType, installState) => (
  sensorsSelectors.selectAll(state).filter((sensor) => {
    if (sensorType && sensorType !== sensor.type) return false;
    if (installState && installState !== sensor.install_state) return false;
    return sensor.ranch === ranchId;
  })
);

const selectSensorsByRanchId = (state, ranchId) => sensorsSelectors
  .selectAll(state)
  .filter((sensor) => sensor.ranch === ranchId);

const selectSensorsForIrrigationBlockId = (state, blockId, sensorType, installState) => (
  sensorsSelectors.selectAll(state).filter((sensor) => {
    if (sensorType && sensorType !== sensor.type) return false;
    if (installState && installState !== sensor.install_state) return false;
    return sensor.irrigation_block === blockId;
  })
);

const selectSensorsForBlockId = (state, blockId, sensorType, installState) => (
  sensorsSelectors.selectAll(state).filter((sensor) => {
    if (sensorType && sensorType !== sensor.type) return false;
    if (installState && installState !== sensor.install_state) return false;
    return sensor.block === blockId;
  })
);

const selectSensorsForGatewayId = (state, gatewayId, sensorType, installState) => (
  sensorsSelectors.selectAll(state).filter((sensor) => {
    if (sensorType && sensorType !== sensor.type) return false;
    if (installState && installState !== sensor.install_state) return false;
    return sensor.gateway === gatewayId;
  })
);

const selectSensor = (state, type, identifier) => sensorsSelectors.selectById(
  state,
  getSensorKey({ type, identifier }),
);

const selectSensorById = (state, type, id) => {
  const sensorsState = selectSensorsState(state);
  const sensorsArray = Object.values(sensorsState.entities);
  return sensorsArray.find((sensor) => sensor.type === type && sensor.id === id);
};

/*
 * This method accepts sensor params of type and either id or identifier
 * It will try to resolve a sensor from the params
 * If no sensor is found, it returns the params
 * It prefers identifier if available
 */
const selectSensorByParams = (state, sensorParams) => {
  if (!sensorParams) return undefined;
  const { identifier, id, type } = sensorParams;

  if (type !== undefined && identifier !== undefined) {
    return selectSensor(state, type, identifier) || sensorParams;
  }

  if (type !== undefined && id !== undefined) {
    return selectSensorById(state, type, id) || sensorParams;
  }

  return sensorParams;
};

const selectLoadingSensors = (state) => selectLoadingState(selectSensorsState(state));

// capabilities

const selectCapabilitiesState = (state) => state.sensorsData.capabilities;
const capabilitiesSelectors = sensorsAdapter.getSelectors(selectCapabilitiesState);
const selectSensorCapabilities = (state, type, identifier) => {
  const capabilities = capabilitiesSelectors.selectById(
    state,
    getSensorKey({ type, identifier }),
  );

  if (capabilities) {
    return capabilities.items || [];
  }
  return [];
};
const selectLoadingCapabilities = (state) => selectLoadingState(selectCapabilitiesState(state));

// status

const selectStatusState = (state) => state.sensorsData.status;
const statusSelectors = sensorsAdapter.getSelectors(selectStatusState);
const selectSensorStatus = (state, type, identifier) => statusSelectors.selectById(
  state,
  getSensorKey({ type, identifier }),
);
const selectLoadingStatus = (state) => selectLoadingState(selectStatusState(state));

const populateSensorStatus = (state, sensors) => sensors.map((sensor) => (
  {
    ...sensor,
    status: selectSensorStatus(state, sensor.type, sensor.identifier),
  }
));

// sensor stats selectors

const selectSensorStatsState = (state) => state.sensorsData.stats.sensor;
const selectCavalierStatsState = (state) => state.sensorsData.stats.cavalier;
const selectGatewayStatsState = (state) => state.sensorsData.stats.gateway;

const sensorStatsSelectors = sensorsAdapter.getSelectors(selectSensorStatsState);
const cavalierStatsSelectors = sensorsAdapter.getSelectors(selectCavalierStatsState);
const gatewayStatsSelectors = sensorsAdapter.getSelectors(selectGatewayStatsState);

const selectSensorStats = (state, type, identifier) => {
  let selector = sensorStatsSelectors;
  if (type === 'gateway') selector = gatewayStatsSelectors;
  if (type === 'cavalier') selector = cavalierStatsSelectors;
  return selector.selectById(
    state,
    getSensorKey({ type, identifier }),
  );
};

const selectSensorErrors = (state, type, identifier) => {
  if (type !== 'cavalier') return null;
  const stats = selectSensorStats(state, type, identifier);
  return stats && stats.errors;
};

const selectAllSensorStats = sensorStatsSelectors.selectAll;
const selectLoadingSensorStats = (state) => selectLoadingState(selectSensorStatsState(state));

const selectAllCavalierStats = cavalierStatsSelectors.selectAll;
const selectLoadingCavalierStats = (state) => selectLoadingState(selectCavalierStatsState(state));

const selectAllGatewayStats = gatewayStatsSelectors.selectAll;
const selectLoadingGatewayStats = (state) => selectLoadingState(selectGatewayStatsState(state));

// sensor note / image selectors

const selectSensorNotesState = (state) => state.sensorsData.notes;
const sensorNotesSelectors = sensorNotesAdapter.getSelectors(selectSensorNotesState);
const selectAllSensorNotes = sensorNotesSelectors.selectAll;
const selectLoadingSensorNotes = (state) => selectLoadingState(selectSensorNotesState(state));

const selectSensorImagesState = (state) => state.sensorsData.images;
const sensorImagesSelectors = sensorImagesAdapter.getSelectors(selectSensorImagesState);
const selectAllSensorImages = sensorImagesSelectors.selectAll;
const selectLoadingSensorImages = (state) => selectLoadingState(selectSensorImagesState(state));

// sensor relationships

const selectPumpFlowMeter = (state, type, identifier) => {
  const pumpController = selectSensor(state, type, identifier);
  if (!pumpController) return undefined;
  const status = selectSensorStatus(state, type, identifier);
  if (!status) return undefined;
  const { flowStatus: { flowMeter: flowMeterIdentifier, flowMeterType } = {} } = status;
  if (!flowMeterIdentifier || !flowMeterType) return undefined;
  const flowMeter = selectSensor(state, flowMeterType, flowMeterIdentifier);
  if (!flowMeter) return undefined;
  return populateSensorStatus(state, [flowMeter])[0];
};

const selectGatewayIsControlEnabled = (state, gatewayId) => {
  const vfds = selectSensorsForGatewayId(state, gatewayId, 'vfd');
  if (vfds && vfds.length) return true;
  const valves = selectSensorsForGatewayId(state, gatewayId, 'valve');
  if (valves && valves.length) return true;
  return false;
};

const selectControlGatewaysForRanchId = (state, ranchId) => {
  const gateways = selectSensorsForRanchId(state, ranchId, 'gateway', 'installed');
  return gateways.filter((gateway) => selectGatewayIsControlEnabled(state, gateway.id));
};

const selectSensorsStatus = (state, sensors) => {
  const status = sensors.map((sensor) => selectSensorStatus(state, {
    type: sensor.sensor_type,
    identifier: sensor.identifier,
  }));
  return status;
};

export {
  selectSensorsState,
  selectAllSensors,
  selectSensorsForIrrigationBlockId,
  selectSensorsForRanchId,
  selectSensorsByRanchId,
  selectSensor,
  selectSensorById,
  selectSensorByParams,
  selectPumpFlowMeter,
  selectLoadingSensors,
  selectSensorsForGatewayId,
  selectGatewayIsControlEnabled,
  selectControlGatewaysForRanchId,
  // capabilities
  selectSensorCapabilities,
  selectLoadingCapabilities,
  // status
  selectSensorStatus,
  selectLoadingStatus,
  populateSensorStatus,
  selectSensorsStatus,
  // stats
  selectSensorStats,
  selectAllSensorStats,
  selectLoadingSensorStats,
  selectAllCavalierStats,
  selectLoadingCavalierStats,
  selectAllGatewayStats,
  selectLoadingGatewayStats,
  selectSensorErrors,
  // notes
  selectAllSensorNotes,
  selectLoadingSensorNotes,
  // images
  selectAllSensorImages,
  selectLoadingSensorImages,
};
