import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sensorsData from './sensor/reducers';
import farmData from './farm/reducers';
import controlData from './control/reducers';
import clientData from './client/reducers';

// If you want to delete the persisted localstorage state on user
// browsers just increment this storeVersion number and commit
const storeVersion = 0;

const appReducer = combineReducers({
  sensorsData,
  farmData,
  controlData,
  clientData: persistReducer(
    {
      key: 'clientData',
      storage,
      blacklist: ['selected'],
    }, clientData,
  ),
  version: persistReducer(
    {
      key: 'version',
      storage,
    }, (state = { val: storeVersion }) => state,
  ),
});

// This logic will check storeVersion value set in this file
// and the one in persisted store and deletes persisted data
// if mismatch
if (localStorage.getItem('persist:version')) {
  const version = JSON.parse(localStorage.getItem('persist:version'));
  if (version.val !== String(storeVersion)) {
    localStorage.removeItem('persist:version');
    localStorage.removeItem('persist:root');
  }
}

const initialState = {};

function rootReducer(state = initialState, action) {
  let appState = state;
  if (action.type === 'RESET') {
    // for all keys defined in your persistConfig(s)
    storage.removeItem('persist:root');
    appState = undefined;
  } else if (action.type === 'persist/REHYDRATE') {
    // This is temporary log for testing
    // console.log('Rehydrating...');
  }
  return appReducer(appState, action);
}

export default rootReducer;
