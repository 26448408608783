import {
  loadAllSensors,
  loadSensorsRanch,
  loadSensorDetail,
  loadSensorDetailById,
  updateSensorLocation,
  createSensor,
  installSensor,
  uninstallSensor,
  removeSensor,
  replaceSensor,
} from './reducers/sensors';

import {
  loadSensorStatsList,
  loadSensorStatsSingle,
  loadCavalierStatsList,
  loadCavalierStatsSingle,
  loadGatewayStatsList,
  loadGatewayStatsSingle,
} from './reducers/stats';

import { loadSensorImagesList } from './reducers/images';
import { loadSensorNotesList } from './reducers/notes';
import { loadSensorStatus } from './reducers/status';
import { loadSensorCapabilities } from './reducers/capabilities';

const loadSensorErrors = loadCavalierStatsSingle;

export {
  loadAllSensors,
  loadSensorsRanch,
  loadSensorDetail,
  loadSensorDetailById,
  // actions
  updateSensorLocation,
  createSensor,
  installSensor,
  uninstallSensor,
  removeSensor,
  replaceSensor,

  loadSensorCapabilities,
  loadSensorStatus,
  // stats
  loadSensorStatsList,
  loadSensorStatsSingle,
  loadCavalierStatsList,
  loadCavalierStatsSingle,
  loadGatewayStatsList,
  loadGatewayStatsSingle,
  loadSensorErrors,
  // notes
  loadSensorNotesList,
  loadSensorImagesList,
};
