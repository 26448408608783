export const initialState = {
  loading: false,
  currentRequestId: undefined,
  error: null,
};

export const selectLoadingState = ({
  loading, lastUpdated, error, currentRequestId,
}) => ({
  loading, lastUpdated, error, currentRequestId,
});

export const selectSensorKeys = ({
  type, identifier, id,
}) => ({
  type, identifier, id,
});

export const requestList = (state, action) => {
  if (state.loading === false) {
    state.error = undefined;
    state.loading = true;
    state.currentRequestId = action.meta.requestId;
  }
};

export const receiveList = (adapter) => (state, action) => {
  const { requestId } = action.meta;
  if (state.loading === true && state.currentRequestId === requestId) {
    state.loading = false;
    const lastUpdated = Date.now();
    state.lastUpdated = lastUpdated;
    state.error = undefined;
    adapter.setAll(state, action.payload);
    state.currentRequestId = undefined;
  }
};

export const receiveListCheckDate = (adapter) => (state, action) => {
  const { requestId } = action.meta;
  if (state.loading === true && state.currentRequestId === requestId) {
    state.error = undefined;
    state.loading = false;
    const lastUpdated = Date.now();
    state.lastUpdated = lastUpdated;
    if (!state.entities.length) {
      adapter.setAll(state, action.payload);
    } else {
      for (const item of action.payload) {
        const id = adapter.selectId(item);
        const current = adapter.getSelectors(state).selectById(id);
        if (current.date < item.date) {
          adapter.upsertOne(state, action.payload);
        }
      }
    }
    state.currentRequestId = undefined;
  }
};

export const requestListFailed = (state, action) => {
  const { requestId } = action.meta;
  if (state.loading === true && state.currentRequestId === requestId) {
    state.loading = false;
    state.error = action.error;
    state.currentRequestId = undefined;
  }
};

export const requestItemById = (adapter) => (state, action) => {
  const id = action.meta.arg;
  adapter.upsertOne(state, {
    id,
    loading: true,
    currentRequestId: action.meta.requestId,
    error: undefined,
  });
};

export const receiveItem = (adapter) => (state, action) => {
  if (!action.payload) return;
  const { payload: item } = action;
  item.lastUpdated = Date.now();
  item.loading = false;
  item.error = undefined;
  adapter.upsertOne(state, item);
};

export const receiveItemCheckDate = (adapter) => (state, action) => {
  if (!action.payload) return;
  const { payload: item } = action;
  item.lastUpdated = Date.now();
  item.loading = false;
  item.error = undefined;
  const id = adapter.selectId(item);
  const current = adapter.getSelectors(state).selectById(id);
  if (current.date < item.date) {
    adapter.upsertOne(state, item);
  }
};

export const requestItemByIdFailed = (adapter) => (state, action) => {
  const id = action.meta.arg;
  adapter.upsertOne(state, {
    id,
    loading: false,
    error: action.error,
    currentRequestId: undefined,
  });
};

/*
 * Methods that pass multiple params
 * These can be used for sensor request that take { type, identifier } as the args
 */
export const requestItemByParams = (adapter) => (state, action) => {
  const item = action.meta.arg;
  adapter.upsertOne(state, {
    ...item,
    loading: true,
    error: undefined,
    currentRequestId: action.meta.requestId,
  });
};

export const receiveItemUpsertNested = (adapter) => (state, action) => {
  // if (!action.payload) return;
  const item = action.meta.arg;
  adapter.upsertOne(state, {
    ...item,
    loading: false,
    error: undefined,
    lastUpdated: Date.now(),
    items: action.payload,
  });
};

export const requestItemByParamsFailed = (adapter) => (state, action) => {
  const item = action.meta.arg;
  adapter.upsertOne(state, {
    ...item,
    loading: false,
    error: action.error,
    currentRequestId: undefined,
  });
};

export const loadList = ({
  getData,
  getRequestState,
}) => async (arg, { getState, requestId }) => {
  const { currentRequestId, loading } = getRequestState(getState());
  if (loading !== true || requestId !== currentRequestId) {
    return null;
  }
  const response = await getData();
  return response.data;
};

export const loadListById = ({
  getData,
}) => async (id, { getState, requestId }) => {
  const state = getState();
  const { currentRequestId, loading } = state.sensorsData.sensors;
  if (loading !== true) {
    return null;
  }
  if (requestId !== currentRequestId) {
    return null;
  }
  const response = await getData(id);
  return response.data;
};

export const loadDetail = ({
  getData,
  getRequestState,
}) => async (id, { getState, requestId }) => {
  const { currentRequestId, loading } = getRequestState(id, getState());
  if (loading !== true || requestId !== currentRequestId) {
    return null;
  }
  const response = await getData(id);
  return response.data;
};

export const loadByTypeIdentifier = ({
  getData,
  getRequestState,
}) => async ({ type, identifier }, { getState, requestId }) => {

  if (type === undefined || identifier === undefined) {
    return null;
  }
  const { currentRequestId, loading } = getRequestState(getState(), type, identifier);
  if (loading !== true || requestId !== currentRequestId) {
    return null;
  }
  const response = await getData(type, identifier);
  return response.data;
};

export const loadByTypeId = ({
  getData,
  getRequestState,
}) => async ({ type, id }, { getState, requestId }) => {
  if (type === undefined || id === undefined) {
    return null;
  }
  const requestState = getRequestState(getState(), type, id);

  // if requestState is undefined, then no match could be found
  // in this case, no need to worry about existing loading state
  if (requestState) {
    const { currentRequestId, loading } = requestState;
    if (loading !== true || requestId !== currentRequestId) {
      return null;
    }
  }
  const response = await getData(type, id);
  return response.data;
};
