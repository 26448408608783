import { scheduleAdapter } from './slices/schedule';
import { selectLoadingState, initialState } from '../helpers';

const selectScheduleState = (state) => state.controlData.schedule;
const scheduleSelectors = scheduleAdapter.getSelectors(selectScheduleState);
const selectBlockSchedule = scheduleSelectors.selectById;
const selectAllBlockSchedule = scheduleSelectors.selectAll;

const selectLoadingBlockSchedule = (state, blockId) => {
  const blockSchedule = selectBlockSchedule(state, blockId);
  if (!blockSchedule) return initialState;
  return selectLoadingState(blockSchedule);
};

export {
  selectBlockSchedule,
  selectLoadingBlockSchedule,
  selectAllBlockSchedule,
};
