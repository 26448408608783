import React, {
  useState, useRef, useEffect, useContext,
} from 'react';
import { useDispatch } from 'react-redux';
import { actions } from 'farmx-redux-core';
import { controlApi } from 'farmx-api';
import PropTypes from 'prop-types';
import { Switch, message } from 'antd';
import {
  WarningFilled, CheckOutlined, LoadingOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ConfirmValveOverrideDialog from './ConfirmValveOverrideDialog';
import styles from './CommonStyles.less';
import ControlSummaryRefreshContext from './RefreshContext';

export const VALVE_TYPE = 'valve';
export const VFD_TYPE = 'vfd';

const {
  loadSensorStatus,
} = actions;

const { createOverride, endOverride } = controlApi;

export default function OverrideStatus({
  type, overrideStatus, valveIdentifier, vfdIdentifier, confirmDialogMessages, loading,
  currentState,
}) {
  const dispatch = useDispatch();
  const [toggleChanged, setToggleChanged] = useState(false);
  const HTTP_SUCCESS_RES_CODE = 200;
  const OVERRIDE_STATE_CHECK_INTERVAL = 5000;
  const OVERRIDE_STATE_CHECK_TIMEOUT = 15000;
  const refreshStatusInterval = useRef(null);
  const valveOverrideSyncState = useRef('pending');
  const refreshId = useContext(ControlSummaryRefreshContext);
  const { t } = useTranslation();

  // confirm override dialog attr
  const OVERRIDE_CONFIRM_DIALOG_TITLE = type === VALVE_TYPE
    ? 'Confirm Valve Override' : 'Confirm Pump Override';

  const buttonText = type === VALVE_TYPE
    ? ['CLOSE', 'OPEN'] : ['OFF', 'ON'];
  useEffect(() => {
    if (refreshId && valveIdentifier) {
      dispatch(loadSensorStatus({ type: VALVE_TYPE, identifier: valveIdentifier }));
    }
    if (refreshId && vfdIdentifier) {
      dispatch(loadSensorStatus({ type: VFD_TYPE, identifier: vfdIdentifier }));
    }
  }, [dispatch, valveIdentifier, vfdIdentifier, refreshId]);

  function refreshSensorStatus(param) {
    dispatch(loadSensorStatus(param));
  }

  function clearRefreshInterval() {
    if (refreshStatusInterval.current) {
      clearInterval(refreshStatusInterval.current);
      refreshStatusInterval.current = null;
    }
  }

  function refreshUntilSynced() {
    refreshStatusInterval.current = setInterval(() => {
      if (valveOverrideSyncState.current !== 'pending'
      ) {
        clearRefreshInterval();
      } else {
        if (type === VALVE_TYPE) {
          refreshSensorStatus({ type, identifier: valveIdentifier });
        } if (type === VFD_TYPE) {
          refreshSensorStatus({ type, identifier: vfdIdentifier });
        }
      }
    }, OVERRIDE_STATE_CHECK_INTERVAL);
    setTimeout(clearRefreshInterval, OVERRIDE_STATE_CHECK_TIMEOUT);
  }

  const onConfirmValveOverride = (overOp) => {
    if (overOp === 'OPEN' || overOp === 'CLOSE'
    || overOp === 'OFF' || overOp === 'ON') {
      let newState;
      let paramObj;
      if (type === VALVE_TYPE) {
        newState = overOp === 'OPEN' ? 'open' : 'closed';
        paramObj = {
          sensorType: VALVE_TYPE,
          sensorIdentifier: valveIdentifier,
          state: newState,
        };
      } else if (type === VFD_TYPE) {
        newState = overOp === 'ON' ? 'on' : 'off';
        paramObj = {
          sensorType: VFD_TYPE,
          sensorIdentifier: vfdIdentifier,
          state: newState,
        };
      }
      if (paramObj) {
        const response = createOverride({
          sensorType: type === VALVE_TYPE ? VALVE_TYPE : VFD_TYPE,
          sensorIdentifier: type === VALVE_TYPE ? valveIdentifier : vfdIdentifier,
          state: newState,
        });
        response.then((res) => {
          if (res.status === HTTP_SUCCESS_RES_CODE) {
            if (type === VALVE_TYPE) {
              refreshSensorStatus({ type: VALVE_TYPE, identifier: valveIdentifier });
            } else {
              refreshSensorStatus({ type: VFD_TYPE, identifier: vfdIdentifier });
            }
            message.success(t('Created override'));
            refreshUntilSynced();
            setToggleChanged(false);
          } else {
            message.error(t('Failed to create override'));
          }
        });
      }
    } else {
      setToggleChanged(false);
    }
  };

  const onChangeToggle = (checked) => {
    if (!checked) {
      let paramObj;
      if (type === VALVE_TYPE) {
        paramObj = {
          sensorType: VALVE_TYPE,
          sensorIdentifier: valveIdentifier,
        };
      } else if (type === VFD_TYPE) {
        paramObj = {
          sensorType: VFD_TYPE,
          sensorIdentifier: vfdIdentifier,
        };
      }
      if (paramObj) {
        const response = endOverride(paramObj);
        response.then((res) => {
          if (res.status === HTTP_SUCCESS_RES_CODE) {
            if (type === VALVE_TYPE) {
              refreshSensorStatus({ type: VALVE_TYPE, identifier: valveIdentifier });
              message.success(t('Ended valve override'));
            } else {
              refreshSensorStatus({ type: VFD_TYPE, identifier: vfdIdentifier });
              message.success(t('Ended pump override'));
            }
            refreshUntilSynced();
          } else if (type === VALVE_TYPE) {
            message.error(t('Failed to end valve override'));
          } else {
            message.error(t('Failed to end pump override'));
          }
        });
      }
    } else {
      setToggleChanged(true);
    }
  };

  const overrideActive = !!(overrideStatus);
  if (overrideActive) {
    valveOverrideSyncState.current = overrideStatus.syncStatus;
  }

  function getOverrideStateColor(overrideStatusObj) {
    if (overrideStatusObj && overrideStatusObj.state) {
      if (overrideStatusObj.state === 'open' || overrideStatusObj.state === 'on') {
        return 'green';
      }
      return 'red';
    }
    return null;
  }

  function getValveOverrideStateTitle(overrideStatusObj) {
    if (overrideStatusObj && overrideStatusObj.state) {
      return overrideStatusObj.state.toUpperCase();
    }
    return 'unknown';
  }

  function getSyncStatusIcon(overrideStatusObj) {
    let icons;
    if (!overrideStatus || !overrideStatus.syncStatus) icons = null;
    if (overrideStatusObj) {
      if (overrideStatusObj.syncStatus === 'success') icons = <CheckOutlined />;
      if (overrideStatusObj.syncStatus === 'pending') icons = <LoadingOutlined />;
      if (overrideStatusObj.syncStatus === 'error') icons = <WarningFilled />;
      if (overrideStatusObj.syncStatus === 'aborted') icons = <WarningFilled />;
    }
    return icons;
  }

  function getControlColumn() {
    if (loading) return <LoadingOutlined className={styles.tagIcon} />;
    if (currentState && !loading) {
      return (
        <Switch
          style={{
            background: overrideActive
              ? getOverrideStateColor(overrideStatus)
              : null,
            width: 'auto',
            minWidth: '57px',
          }}
          checkedChildren={t(getValveOverrideStateTitle(overrideStatus))}
          unCheckedChildren={t('NONE')}
          data-testid={valveIdentifier || vfdIdentifier}
          checked={overrideActive}
          onChange={onChangeToggle}
        />
      );
    }
    if (currentState === 'unknown' || !currentState) {
      return <WarningFilled className={styles.tagIconOverride} />;
    }
    return <LoadingOutlined className={styles.tagIcon} />;
  }
  return (
    <div className={styles.switch}>
      <div className={styles.switchLeft}>
        {getControlColumn()}
      </div>
      <div className={styles.switchRight}>
        {getSyncStatusIcon(overrideStatus)}
      </div>
      <div />
      {toggleChanged
        ? (
          <ConfirmValveOverrideDialog
            title={OVERRIDE_CONFIRM_DIALOG_TITLE}
            displayText={confirmDialogMessages}
            buttonText={buttonText}
            onClick={onConfirmValveOverride}
          />
        ) : ''}
    </div>
  );
}

OverrideStatus.propTypes = {
  type: PropTypes.string,
  overrideStatus: PropTypes.shape({
    state: PropTypes.string,
    syncStatus: PropTypes.string,
  }),
  valveIdentifier: PropTypes.string,
  vfdIdentifier: PropTypes.string,
  confirmDialogMessages: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  currentState: PropTypes.string,
};

OverrideStatus.defaultProps = {
  type: null,
  overrideStatus: null,
  valveIdentifier: null,
  vfdIdentifier: null,
  confirmDialogMessages: [],
  loading: null,
  currentState: null,
};
