import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { StyleSheet, css } from 'aphrodite';
import DropdownModal from './DropdownModal';
import styles from '../CommonStyle.less';
import SensorSelectContext from './SensorSelectContext';

const { Option } = Select;

export default function InstallStateSelect(props) {
  const { selectedState, installStates, onChange } = props;
  const { t } = useTranslation();
  const [modal, setModalState] = useState(false);
  const mobileScreen = window.innerWidth < 599;
  let dropdownStyle = null;
  if (mobileScreen) dropdownStyle = { minWidth: '60%' };
  const dropdownStyleValues = useContext(SensorSelectContext);

  const getDropDownClass = (dropDownStyle) => {
    let styleClassName;
    if (window.innerWidth > 599) {
      if (dropDownStyle && dropDownStyle.leftValue) {
        const createdStyles = StyleSheet.create({
          leftposition: {
            left: `${dropDownStyle.leftValue.current} !important`,
            width: `${dropDownStyle.minWidthValue.current} !important`,
          },
        });
        styleClassName = `${css(createdStyles.leftposition)}`;
      }
    } if (window.innerWidth < 599) {
      styleClassName = `${styles.selectDropDown} `;
    }
    return styleClassName;
  };

  return (
    <Select
      disabled={installStates.length === 1 && selectedState}
      style={{ width: '100%' }}
      allowClear
      value={selectedState}
      placeholder={t('Install State')}
      optionLabelProp="label"
      dropdownMatchSelectWidth={false}
      onChange={onChange}
      onSelect={!mobileScreen ? () => setModalState(false) : null}
      dropdownStyle={dropdownStyle}
      onFocus={() => setModalState(true)}
      open={mobileScreen ? modal : undefined}
      dropdownClassName={getDropDownClass(dropdownStyleValues)}
      dropdownRender={mobileScreen ? (menu) => (
        <DropdownModal
          title="InstallState Select"
          displayOptions={menu}
          mobileScreen={mobileScreen}
          onClick={() => setModalState(false)}
        />
      ) : undefined}
    >
      {installStates.map((value, i) => (
        <Option
          key={String(i)}
          label={value}
          value={value}
          data-testid={`${value}`}
        >
          {value}
        </Option>
      ))}
    </Select>
  );
}

InstallStateSelect.propTypes = {
  selectedState: PropTypes.string,
  installStates: PropTypes.arrayOf(PropTypes.any),
  onChange: PropTypes.func,
};

InstallStateSelect.defaultProps = {
  selectedState: undefined,
  installStates: [],
  onChange: () => {},
};
