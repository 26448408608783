import { setActions } from './slices/selected';
import { store } from '../store';

const {
  setSelectedBlock, setSelectedRanch, setSelectedBlocks, setSelectedRanches,
  setEntitySidebarCollapseState, setUrlLoad,
} = setActions;

const setRanches = (ranchId) => (dispatch) => {
  const { ranches } = store.getState().clientData.selected;
  if (ranches.payload && ranches.payload.indexOf(String(ranchId)) === -1) {
    let selectedRanches = ranches.payload;
    if (selectedRanches.length) selectedRanches = selectedRanches.concat(String(ranchId));
    else selectedRanches = [String(ranchId)];
    dispatch(setSelectedRanches(selectedRanches));
  } else if (ranches && !ranches.payload && !ranches.length) {
    dispatch(setSelectedRanches([String(ranchId)]));
  }
};

const setBlocks = (blockIds) => (dispatch) => {
  if (blockIds) {
    const stateObject = store.getState().farmData.blocks;
    const blocks = Object.values(stateObject.entities);
    let ranchIdForBlockId = [];
    if (blocks.length) {
      ranchIdForBlockId = blocks.filter((e) => blockIds.indexOf(e.id) > -1)
        .map((e) => e.ranch).filter((v, i, a) => a.indexOf(v) === i);
    }
    if (blockIds && blockIds.length === 1) {
      dispatch(setSelectedBlock(blockIds));
      dispatch(setSelectedBlocks(blockIds));
      if (ranchIdForBlockId[0]) {
        const { ranch } = store.getState().clientData.selected;
        if (ranch.payload && ranch.payload.indexOf(ranchIdForBlockId[0]) === -1) {
          dispatch(setSelectedRanch(ranchIdForBlockId));
        } else if (ranch && !ranch.length) {
          dispatch(setSelectedRanch(ranchIdForBlockId));
        }
        dispatch(setRanches(ranchIdForBlockId[0]));
      }
    } else if (blockIds && blockIds.length > 1) {
      dispatch(setSelectedBlock([]));
      dispatch(setSelectedBlocks(blockIds));
      if (ranchIdForBlockId.length > 1) {
        dispatch(setSelectedRanch([]));
      } else dispatch(setSelectedRanch(ranchIdForBlockId));
    } else {
      dispatch(setSelectedRanch([]));
      dispatch(setSelectedBlock([]));
      dispatch(setSelectedBlocks([]));
    }
  }
};

const setRanch = (ranchId) => (dispatch) => {
  if (ranchId) {
    dispatch(setSelectedRanch([ranchId]));
    dispatch(setRanches(ranchId));
    dispatch(setSelectedBlock([]));
    const stateObject = store.getState().farmData.blocks;
    const blocks = Object.values(stateObject.entities);
    if (blocks.length) {
      const blockIdsForRanchId = blocks.filter((e) => e.ranch === ranchId)
        .map((e) => e.id);
      dispatch(setSelectedBlocks(blockIdsForRanchId));
    }
  } else {
    dispatch(setSelectedRanch([]));
    dispatch(setSelectedBlock([]));
    dispatch(setSelectedBlocks([]));
  }
};

export {
  setSelectedBlock, setSelectedRanch, setSelectedBlocks, setSelectedRanches,
  setEntitySidebarCollapseState, setBlocks, setRanch, setRanches, setUrlLoad,
};
