import React from 'react';
import { Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { sensorTitles, icons } from './constants';
import './ListByRanchId.less';

const skeletonListData = {
  a: [1, 2, 3],
  b: [1, 2, 3],
  c: [1, 2, 3],
  d: [1, 2, 3],
};

export function ListByRanchId({ isMobile, listData, isDataLoading }) {
  const { t } = useTranslation();

  return (
    <div className={`list-by-ranch-id-page${isMobile ? ' list-by-ranch-id-page-mobile' : ''}`}>
      <div className="list-by-ranch-id-page-container">
        {
          isDataLoading && Object.keys(skeletonListData).map((k) => (
            <div key={k}>
              <Skeleton.Input style={{ width: 600 }} active size="small" />
              {skeletonListData[k].map((ki) => (
                <div
                  key={`${k}${ki}`}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: '#35363a',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    borderBottom: '1px solid #f0f0f0',
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '10px' }}>
                    <div>
                      <Skeleton.Input style={{ width: 50 }} active size="small" />
                    </div>
                    <div style={{ paddingTop: '5px', fontSize: 'smaller' }}>
                      <Skeleton.Input style={{ width: 200 }} active size="small" />
                    </div>
                  </div>
                  <div />
                </div>
              ))}
            </div>
          ))
        }
        {
          !isDataLoading && Object.keys(listData).map((typeKey) => (
            <div key={typeKey}>
              <div className={`list-header list-header-${typeKey}`}>

                <i className={icons[typeKey] || 'icon-radio-waves'} />

                <span style={{ marginLeft: '10px' }}>{t(sensorTitles[typeKey].plural)}</span>

              </div>
              {
                listData[typeKey].map((sensor) => (
                  <div key={`${typeKey}-${sensor.identifier}`} className={`list-body list-body-${typeKey}-${sensor.identifier}`}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div>
                        {t(sensor.name || 'No name')}
                      </div>
                      <div style={{ fontSize: 'smaller' }}>
                        {t(sensor.identifier)}
                      </div>
                    </div>
                    <div />
                  </div>
                ))
              }
            </div>
          ))
        }
        <div style={{ height: '2em' }} />
      </div>
    </div>
  );
}
