import { store, persistor } from './store';
import * as sensorActions from './sensor/actions';
import * as sensorSelectors from './sensor/selectors';
import * as farmActions from './farm/actions';
import * as farmSelectors from './farm/selectors';
import * as controlActions from './control/actions';
import * as controlSelectors from './control/selectors';
import * as webActions from './client/actions';
import * as webSelectors from './client/selectors';
import hooks from './hooks';

const actions = {
  ...sensorActions,
  ...farmActions,
  ...controlActions,
  ...webActions,
};

const selectors = {
  ...sensorSelectors,
  ...farmSelectors,
  ...controlSelectors,
  ...webSelectors,
};

export {
  store,
  persistor,
  actions,
  hooks,
  selectors,
};
