import React, {
  useRef, useState, useEffect, useCallback,
} from 'react';
import ReactDOMServer from 'react-dom/server';
import { useSelector } from 'react-redux';
import { selectors } from 'farmx-redux-core';
import PropTypes from 'prop-types';
import {
  Button, Badge,
} from 'antd';
import { BellFilled, LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ChartComponent from './ChartComponent';
import image from './static/loader.gif';
import styles from './HighchartsSchedule.less';

const { getEntitySidebarCollapseState } = selectors;
const HighchartsSchedule = ({
  chartData,
  onClickChart,
  dates,
  configChange,
}) => {
  const chartRef = useRef(null);
  const renderTrigger = useRef(true); // To trigger the render and build config
  const [options, setOptions] = useState(null);
  // variable to hold the processed chart data
  const [series, setSeries] = useState([]);
  const sidebarCollapsed = useSelector((state) => getEntitySidebarCollapseState(state)).payload;
  const { t } = useTranslation();
  const colors = {
    recommended: 'rgb(193, 218, 214)',
    past: 'rgb(232, 208, 169)',
    scheduled: 'lightblue',
  };

  const jsonKeys = {
    recommended_events: 'recommended',
    past_events: 'past',
    scheduled_events: 'scheduled',
  };

  // This will be removed in future after confirmation
  // const abbrFlow = (data) => {
  //   if (data) return `, ${data / 1000}k gal`;
  //   return '';
  // };


  // Currently used for comparing the dates in the series with props dates
  const equals = (x, y) => (x.map((d, i) => moment(d).isSame(moment(y[i])))
    .reduce((a, p) => a && p, true));


  const divElement = document.querySelector('.schedule-chart-inner');
  const divHeight = divElement ? divElement.offsetHeight : null;
  // issue: ganttchart y-axis labels are overlapping in mobile screen
  // when there are more than 7 blocks are selected
  // Below height calculation will use to fix the issue.
  let height;
  if (divHeight && chartData && chartData[0] && chartData[0].selectedIrrigationTypes) {
    if (chartData[0].selectedIrrigationTypes.length) {
      const heightVal = chartData.length * chartData[0].selectedIrrigationTypes.length * 50;
      height = heightVal > divHeight ? heightVal : divHeight;
    } else height = divHeight;
  } else height = window.innerHeight / 1.6; // To handle the chart height issue

  /**
   * Function to build the config for the chart
   * It takes chartData and other required values to build config
   * @returns config object.
   */
  const buildChartConfig = useCallback(() => {
    function getAlarmListUrl(blockId) {
      const params = {
        blockId,
        logLevel: 'error',
        active: true,
      };
      const paramStr = new URLSearchParams(params).toString();
      return `/events?${paramStr}`;
    }

    function getControlPageUrl(blockId) {
      const params = {
        blockId,
      };
      const paramStr = new URLSearchParams(params).toString();
      return `/control?${paramStr}`;
    }

    function renderAlarmButton(block) {
      return (
        <a href={getAlarmListUrl(block.blockId)}>
          <Badge
            count={block.blockAlarmCount}
          >
            <Button
              className={styles.bellIconButton}
              icon={<BellFilled className={styles.bellIconSize} />}
            />
          </Badge>
        </a>
      );
    }

    function renderOverrideNotice(block) {
      if (!block.overrideActive) return null;
      return (
        <a
          className={styles.overrideAlert}
          href={getControlPageUrl(block.blockId)}
        >
          {t('Override Active')}
        </a>
      );
    }

    function renderCategory(seriesObj) {
      if (seriesObj.name.includes('<img')) {
        return seriesObj.name;
      }

      const links = ReactDOMServer.renderToStaticMarkup(
        renderOverrideNotice(seriesObj.current),
      );

      const buttons = ReactDOMServer.renderToStaticMarkup(
        <div className={styles.blockButtons}>
          {renderAlarmButton(seriesObj.current)}
        </div>,
      );
      return `${seriesObj.name}<br><span class=${styles.capitalize}>(${
        seriesObj.current.irrigationType})</span><br>`
        + `${links}${buttons}`;
    }

    const abbrDuration = (duration) => {
      const durationHr = duration.asHours();
      if (durationHr % 1 !== 0) return `${durationHr.toFixed(1)} hrs`;
      return `${durationHr.toFixed()} hrs`;
    };

    const abbrPeriod = (data) => {
      const duration = moment.duration(moment(data[1]).diff(moment(data[0])));
      return abbrDuration(duration);
    };

    /**
     * This will be called after chartData get processed
     * @param seriesData - Processed chartData
     * @returns the chartConfig as a result
     */
    function configBuild(seriesData) {
      let chartConfig;

      /**
       * condition to check the seriesData is valid by checking the length
       * Ex: blocks: [1] and selectedIrrigationTypes: ['scheduled', 'past']
       * Then the result data length will be 2
       */
      if (chartData.length * chartData[0].selectedIrrigationTypes.length === seriesData.length) {
        let xaxisDateFormat;
        let dateDiff;
        if (dates) {
          dateDiff = dates[1].diff(dates[0], 'days');
          if (dateDiff > 1 && dateDiff < 7) xaxisDateFormat = '%e %b %Y';
          else if (dateDiff > 7 && dateDiff <= 14) xaxisDateFormat = '%e %b';
          else if (dateDiff > 14) xaxisDateFormat = '%e';
          else if (!dateDiff)xaxisDateFormat = '%H:%M';
          else xaxisDateFormat = '%e %b %Y';
        }
        let xaxisOption;
        if (dates) {
          xaxisOption = [
            {
              tickInterval: dateDiff ? 1000 * 60 * 60 * 24 : 3600 * 1000,
              type: 'datetime',
              min: dates[0].valueOf(),
              max: dates[1].valueOf(),
              currentDateIndicator: true,
              dateTimeLabelFormats: {
                day: xaxisDateFormat,
              },
            },
            {
              startOfWeek: 0,
            },
          ];
        } else {
          xaxisOption = [{
            currentDateIndicator: true,
            grid: {
              cellHeight: 60,
            },
          },
          {
            startOfWeek: 0,
          }];
        }
        const numSeries = seriesData.length;
        const minHeight = numSeries * 100 + 120; // extra 120 is header height
        const chartHeight = (minHeight > height) ? minHeight : height;

        chartConfig = {
          series: seriesData,
          chart: {
            marginLeft: 130,
            animation: false,
            height: height ? `${chartHeight}px` : 'calc(100vh - 100%)',
            reflow: true,
            events: {
              click(e) {
                if (global.chartYaxisCategories.length > 0) {
                  const clickObj = global.chartYaxisCategories[
                    Math.abs(Math.round(e.yAxis[0].value))];
                  if (clickObj) {
                    clickObj.startDate = moment(e.xAxis[0].value);
                    clickObj.irrigationType = global.chartYaxisCategoryTypes[
                      Math.abs(Math.round(e.yAxis[0].value))];
                    onClickChart(clickObj);
                  }
                }
              },
            },
          },
          plotOptions: {
            series: {
              /**
               * Link: https://api.highcharts.com/gantt/plotOptions.series.animation
               * To handle the label flickering
               */
              animation: {
                defer: 0,
                duration: 0,
              },
              dataLabels: {
                enabled: true,
                format: '{point.runtime}',
                style: {
                  cursor: 'default',
                  pointerEvents: 'none',
                  color: 'black',
                },
              },
              states: {
                select: {
                  color: '',
                  borderColor: 'none',
                },
              },
              allowPointSelect: true,
              point: {
                events: {
                  click(e) {
                    onClickChart({
                      scheduleId: e.point.options.schId,
                      blockId: e.point.options.blkId,
                    });
                    if (chartRef.current) {
                      const { chart } = chartRef.current;
                      if (chart) chart.tooltip.hide();
                    }
                  },
                },
              },
            },
          },
          tooltip: {
            pointFormat: '<span>Run Time: {point.runtime}</span><br/>'
          // + '<span>Acre in: {point.acrein}</span><br/>'
          + '<span>From: {point.start:%Y-%m-%d %H:%M:%S}</span><br/>'
          + '<span>To: {point.end:%Y-%m-%d %H:%M:%S}</span><br/>'
          + '<span>Total runtime (in view): {point.totalRuntime}</span><br/>',
            // + '<span>Season Acre in: {point.seasonAcrein}</span>',
          },
          xAxis: xaxisOption,
          yAxis: {
            type: 'category',
            labels: {
              useHTML: true,
              style: {
                width: '90px',
                minWidth: '90px',
                textAlign: 'center',
                paddingTop: '10px',
                paddingBottom: '10px',
              },
            },
            grid: {
              columns: [{
                title: {
                  text: t('Blocks'),
                },
                categories: seriesData.map((s) => renderCategory(s)),
              }],
            },
          },
        };
      }
      return chartConfig;
    }

    let defaultOption;
    if (renderTrigger.current) {
      const seriesData = [];
      let count = 0;
      let yHeight = 0;
      global.chartYaxisCategories = [];
      global.chartYaxisCategoryTypes = [];
      // for each object in chartData, i
      chartData.forEach((block, i) => {
        // for each IrrigationType, j
        Object.keys(jsonKeys).forEach((irrgType, j) => {
          // get events array
          const irrgEventArr = block[irrgType];
          const obj = {};
          // set date
          obj.selectedDates = dates;
          // set name
          obj.name = block.name
            ? block.name
            : `<img id=${styles.loadingBlock} src=${image} alt="Loading..." />`;
          // push name to categories
          if (block.selectedIrrigationTypes
        && block.selectedIrrigationTypes.includes(jsonKeys[irrgType])) {
            global.chartYaxisCategories.push({
              name: block.name,
              id: block.id,
              startDate: null,
            });
            global.chartYaxisCategoryTypes.push(jsonKeys[irrgType]);
          }

          const pumpOverride = block.blockControlStatus
        && block.blockControlStatus.vfdStatus
        && block.blockControlStatus.vfdStatus.overrideStatus;

          const valveOverride = block.blockControlStatus
        && block.blockControlStatus.valveStatus
        && block.blockControlStatus.valveStatus.overrideStatus;

          const overrideActive = !!(valveOverride || pumpOverride);

          // set current object with values
          obj.current = {
            name: block.name,
            irrigationType: jsonKeys[irrgType],
            blockId: block.id,
            blockAlarmCount: block.blockControlStatus
          && block.blockControlStatus.valveStatus
          && block.blockControlStatus.valveStatus.blockAlarmCount,
            overrideActive,
          };
          obj.current.irrigationType = jsonKeys[irrgType];

          if (irrgType === 'recommended_events') {
            obj.current.totalRuntime = block.totalRuntimeRecommended;
          } else if (irrgType === 'scheduled_events') {
            obj.current.totalRuntime = block.totalRuntimeScheduled;
          } else if (irrgType === 'past_events') {
            obj.current.totalRuntime = block.totalRuntimePast;
          }

          obj.data = [];
          if (!irrgEventArr || !irrgEventArr.length) {
            // set data with array of object with values
            if (block.selectedIrrigationTypes
          && block.selectedIrrigationTypes.includes(jsonKeys[irrgType])) {
              obj.data.push({
                id: `block-${count}`,
                y: yHeight,
              });
              count += 1;
              seriesData.push(obj);
              yHeight += 1;
            }
          }
          if (irrgEventArr) {
            if (irrgEventArr.length > 0) {
              // set data with array of object with values
              irrgEventArr.forEach((event) => {
                obj.data.push({
                  id: `block-${count}`,
                  schId: event.id,
                  blkId: block.id,
                  runtime: abbrPeriod([event.start_date, event.stop_date]),
                  // totalRuntime: abbrDuration(obj.current.totalRuntime),
                  flow: event.flow,
                  start: Date.parse(event.start_date),
                  end: Date.parse(event.stop_date),
                  y: yHeight,
                  color: colors[jsonKeys[irrgType]],
                });
                count += 1;
              });
              seriesData.push(obj);
              yHeight += 1;
            }
          }
          // Condition to set the processed data to state once it done
          if (chartData.length === (i + 1) && Object.keys(jsonKeys).length === (j + 1)) {
            setSeries(seriesData);
            defaultOption = configBuild(seriesData);
          }
        });
      });
    }
    return defaultOption;
  }, [chartData, t, jsonKeys, colors, dates, height, onClickChart]);

  // To trigger the buildChartConfig when the chartData/dates get changed
  useEffect(() => {
    if (!renderTrigger.current && ((chartData.length
      * ((chartData[0] && chartData[0].selectedIrrigationTypes) || []).length !== series.length)
      || (series.length && !equals(series[0].selectedDates, dates)))) {
      renderTrigger.current = true;
    }
  }, [chartData, dates, series]);

  /**
   * Calls the buildChartConfig() function to set options to state
   * Also sets the 'false' to renderTrigger to avoid repeated calls
   */
  useEffect(() => {
    if (renderTrigger.current && !configChange) {
      const config = buildChartConfig();
      if (config) {
        renderTrigger.current = false;
        setOptions(config);
      }
    }
  }, [buildChartConfig, configChange]);

  useEffect(() => {
    if (chartRef.current && divHeight && window.innerWidth > 599) {
      // 20 -> element padding, 320 -> entitySideBar width
      const width = window.innerWidth - 20;
      if (!sidebarCollapsed) {
        chartRef.current.chart.setSize(width - 320, divHeight, false);
      } else {
        chartRef.current.chart.setSize(width, divHeight, false);
      }
    }
  }, [divHeight, sidebarCollapsed]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (chartRef.current && chartRef.current.chart) chartRef.current.chart.redraw();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // Below code will be removed in future after confirmation
  // if (chartRef.current) {
  //   const { chart } = chartRef.current;
  //   // highcharts API for loading: https://api.highcharts.com/highcharts/loading
  //   // default: Loading
  //   // Spinner instead of Loading texs: https://stackoverflow.com/questions/12148276/highcharts-how-to-show-loading-animation-at-set-data
  //   chart.showLoading(`<img id=${styles.loadingData} src=${image} alt="Loading..." />`);
  //   setTimeout(() => chart.hideLoading(), 1500);
  // }

  window.onresize = function onresize() {
    const chartContainer = document.querySelector('.schedule-chart-outer');
    if (chartContainer) {
      const width = chartContainer.clientWidth;
      const boxheight = chartContainer.clientHeight;
      if (chartRef.current) {
        const { chart } = chartRef.current;
        // - The third args is an animation set to true.
        if (boxheight > 400) {
          if (boxheight > height) chart.setSize(width, boxheight, false);
          else chart.setSize(width, height, false);
        }
      }
    }
  };

  return (
    <div className={styles.chartDiv}>
      {
      options
        ? (
          <ChartComponent
            constructorType="ganttChart"
            options={options}
            chartRef={chartRef}
          />
        ) : null
      }
      {renderTrigger.current ? <LoadingOutlined className={styles.loader} /> : null}
    </div>
  );
};

HighchartsSchedule.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.any),
  dates: PropTypes.arrayOf(PropTypes.object),
  onClickChart: PropTypes.func,
  configChange: PropTypes.bool,
};

HighchartsSchedule.defaultProps = {
  chartData: null,
  dates: null,
  onClickChart: () => null,
  configChange: null,
};

export default HighchartsSchedule;
