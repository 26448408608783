import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Collapse, Table, Tag, Button, Tooltip, Typography, Badge,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { selectors } from 'farmx-redux-core';
import { FaCalendarAlt } from 'react-icons/fa';
import { WarningFilled, LoadingOutlined, BellFilled } from '@ant-design/icons';
import styles from './CommonStyles.less';
import OverrideStatus, { VALVE_TYPE } from './OverrideStatus';
import BlockControlSensorList from './BlockControlSensorList';
import { getValveScheduleMessage, getPumpScheduleMessageBasic } from './scheduleMessage';

const { Panel } = Collapse;
const { Text } = Typography;

const {
  selectBlockSensorStatus,
} = selectors;

export default function BlockControlList({
  blocks,
  onClickCalendar,
  blockLoading,
}) {
  const filteredBlocks = blocks ? blocks.filter((d) => d.valve) : null;
  const blocksUnsorted = useSelector((state) => selectBlockSensorStatus(state, filteredBlocks));
  const blockData = blocksUnsorted.sort((a, b) => a.blockName.localeCompare(b.blockName));

  const { t } = useTranslation();

  const getMessageClassName = (state, online) => {
    const styleStr = `${styles.redMessage} `;
    const styleStrNot = `${styles.normalMessage} `;
    if (state === 'unknown' || (!online && state === 'closed')) {
      return styleStr;
    }
    if (!state) return styleStr;
    return styleStrNot;
  };

  const getSchedule = (block, text) => (
    <div
      className={styles.iconContainer}
      onClick={() => onClickCalendar({ id: block.blockId })}
      role="presentation"
    >
      <div className={styles.icon}>
        <FaCalendarAlt />
      </div>
      <div className={getMessageClassName(block.valveCurrentState, block.valveOnline)}>
        <span className={styles.scheduleContent}>
          {text}
        </span>
      </div>
    </div>
  );

  const getIndClass = (blockStatus) => {
    const styleStr = `${styles.indicator} `;
    if (!blockStatus.valveLoading) {
      if (blockStatus.valveControlEnabled) {
        if (!blockStatus.valveOnline) return styleStr + styles.red;
        if (blockStatus.valveOnline
          && blockStatus.valveCurrentState === 'closed') return styleStr + styles.lightgrey;
        if (blockStatus.valveOnline
          && blockStatus.valveCurrentState === 'open') return styleStr + styles.blue;
      }
    }
    return styleStr + styles.lightgrey;
  };

  const getCurrentState = (block) => {
    const color = block.valveCurrentState === 'open' ? '#1890FF' : '#D40202';
    if (block.valveLoading) {
      return <LoadingOutlined className={styles.tagIcon} />;
    }
    if (block.valveCurrentState) {
      if (block.valveCurrentState !== 'unknown') {
        if (block.valveOnline && block.valveCurrentState === 'closed') {
          return (
            <Tag color="" key={block.valveCurrentState} className={styles.tagIcon}>
              {t('Closed').toUpperCase()}
            </Tag>
          );
        }
        const state = block.valveCurrentState === 'open' ? t('Open').toUpperCase() : block.valveCurrentState;
        return (
          <Tag
            color={color}
            key={block.valveCurrentState}
            className={[styles.tagIcon, styles.tagIconIrrigating]}
          >
            {state.toUpperCase()}
          </Tag>
        );
      }
      return <WarningFilled className={styles.warningIcon} />;
    }
    if (!block.valveCurrentState && !block.valveLoading) {
      return <WarningFilled className={styles.warningIcon} />;
    }
    return <LoadingOutlined className={styles.tagIcon} />;
  };

  const getRowClassName = (record) => {
    if (record.valveCurrentState) {
      if (!record.valveControlEnabled
        || record.valveCurrentState === 'unknown') {
        return styles['red-border-text'];
      }
      if (record.valveControlEnabled
        && (!record.valveOnline
          && (record.valveCurrentState === 'closed' || !record.valveCurrentState))) {
        return styles['red-border-text'];
      }
    }
    if (!record.valveLoading && (!record.valveControlEnabled
      || !record.valveCurrentState)) {
      return styles['red-border-text'];
    }
    const className = getIndClass(record);
    return className;
  };

  function widthCalc(columns) {
    const maxwid = 100;
    const result = columns().map((element) => {
      const values = element;
      if (element.title === 'Alarm') {
        values.width = 100;
      } if (element.title === 'Override') {
        values.width = 100;
      } if (element.title !== 'Override' && element.title !== 'Alarm') {
        values.width = `${(maxwid - 10) / (columns().length - 2)}%`;
      }
      return values;
    });
    return result;
  }

  function getAlarmListUrl(block) {
    const params = {
      blockId: block.blockId,
      logLevel: 'error',
      active: true,
    };
    const paramStr = new URLSearchParams(params).toString();
    return `/events?${paramStr}`;
  }

  const columns = () => [
    {
      title: t('Block'),
      className: styles.scheduleItemHeader,
      render: (block) => {
        if (block.blockName) {
          return (
            <Tooltip title={block.blockName}>
              <span>{block.blockName}</span>
            </Tooltip>
          );
        }
        return <span style={{ color: 'lightgray' }}>{t('No Name')}</span>;
      },
    },
    {
      title: t('Schedule'),
      className: styles.scheduleHeader,
      render: (block) => {
        if (block.valveControlEnabled) {
          return getSchedule(block, getValveScheduleMessage(
            t,
            block.valveOnline,
            block.valveCurrentState,
            block.valveScheduledOpen,
            block.valveScheduledClose,
            block.valveLoading,
            block.valveOverrideStatus && block.valveOverrideStatus.state,
          ));
        }
        return t('Control not enabled');
      },
    },
    {
      title: t('Override'),
      className: styles.overrideHeader,
      render: (block) => {
        if (block.valveControlEnabled) {
          return (
            <OverrideStatus
              type={VALVE_TYPE}
              overrideStatus={block.valveOverrideStatus}
              valveIdentifier={block.valveIdentifier}
              vfdIdentifier={block.vfdIdentifier}
              loading={block.valveLoading}
              currentState={block.valveCurrentState}
              confirmDialogMessages={[
                getPumpScheduleMessageBasic(
                  t,
                  block.pumpStartDate,
                  block.pumpStopDate,
                ),
                getValveScheduleMessage(
                  t,
                  block.valveOnline,
                  block.valveCurrentState,
                  block.valveScheduledOpen,
                  block.valveScheduledClose,
                )]}
            />
          );
        }
        return null;
      },
    },
    {
      title: t('State'),
      className: styles.stateHeader,
      render: (block) => {
        if (block.valveControlEnabled) {
          return (
            <div className={styles.status}>
              {getCurrentState(block)}
            </div>
          );
        }
        return null;
      },
    },
    {
      title: t('Alarms'),
      className: styles.alarmsHeader,
      render: (block) => (
        <Link to={getAlarmListUrl(block)}>
          <Badge
            count={block.blockAlarmCount}
          >
            <Button
              className={styles.bellIconButton}
              icon={<BellFilled className={styles.bellIconSize} />}
            />
          </Badge>
        </Link>
      ),
    },
  ];

  function renderBlockDetail(block) {
    return <BlockControlSensorList blockId={block.blockId} />;
  }

  return (
    <div className={styles.listContainer}>
      <Collapse
        expandIconPosition="right"
        defaultActiveKey={['1']}
      >
        <Panel
          header={(
            <div>
              <Text style={{ fontSize: '16px' }}>{t('Blocks')}</Text>
              {blockLoading
                ? <LoadingOutlined style={{ marginLeft: '10px', fontSize: '16px' }} />
                : ''}
            </div>
          )}
          key="1"
          className={styles.collapseHeader}
        >
          {blockData.length
            ? (
              <Table
                className={styles['custom-table']}
                rowClassName={getRowClassName}
                dataSource={blockData}
                columns={widthCalc(columns)}
                pagination={false}
                rowKey={(record) => String(record.blockId)}
                expandable={{
                  expandRowByClick: true,
                  rowExpandable: () => true,
                  expandedRowRender: (block) => renderBlockDetail(block),
                }}
              />
            ) : (
              <section className={styles['configuration-message']}>
                No Valves Configured
              </section>
            )}
        </Panel>
      </Collapse>
    </div>
  );
}

BlockControlList.propTypes = {
  blocks: PropTypes.arrayOf(PropTypes.object),
  onClickCalendar: PropTypes.func,
  blockLoading: PropTypes.bool,
};

BlockControlList.defaultProps = {
  blocks: [],
  onClickCalendar: null,
  blockLoading: null,
};
