import React, { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions, selectors } from 'farmx-redux-core';
import PropTypes from 'prop-types';
import { Tag, Select } from 'antd';
import ItemSelect from './ItemSelect';
import RanchOption from './RanchOption';
import DropdownModal from './sensor/DropdownModal';
import SensorSelectContext from './sensor/SensorSelectContext';

const { Option } = Select;

const { loadFarmData } = actions;

const {
  selectAllRanches,
} = selectors;

const ranchTagRender = (args) => {
  const { label, closable, onClose } = args;
  return (
    <Tag
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label.props ? label.props.ranch.name : label}
    </Tag>
  );
};

const defaultItemRenderer = (d) => (
  <Option key={d.id} value={d.id} label={d.name} data-testid={`${d.name}`}>
    <RanchOption ranch={d} />
  </Option>
);

const RanchSelect = ({
  loadItems,
  items,
  mode,
  renderItem,
  onSelect,
  onChange,
  tagRender,
  placeholder,
  defaultOption,
  value,
}) => {
  const dispatch = useDispatch();
  const data = useSelector(selectAllRanches);
  const ranches = items || data;
  const [modal, setModalState] = useState(false);
  const mobileScreen = window.innerWidth < 599;
  const dropdownStyleValues = useContext(SensorSelectContext);

  useEffect(() => {
    if (loadItems) loadItems();
    else dispatch(loadFarmData());
  }, [dispatch, loadItems]);

  let dropdownStyle = null;
  if (window.innerWidth < 599) dropdownStyle = { minWidth: '60%' };
  function onSelectAction(d) {
    if (onSelect) onSelect(d);
    if (!mobileScreen) setModalState(false);
  }
  return (
    <ItemSelect
      mode={mode}
      renderItem={renderItem}
      items={ranches}
      onSelect={onSelectAction}
      onChange={onChange}
      tagRender={tagRender || ranchTagRender}
      placeholder={placeholder}
      defaultOption={defaultOption}
      value={value}
      filterOption={(input, option) => option.children.props.ranch.name.toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
        || (option.children.props.ranch.entity
          && option.children.props.ranch.entity.name
            .toLowerCase().indexOf(input.toLowerCase()) >= 0)}
      dropdownStyle={dropdownStyle}
      menuIsOpen={mobileScreen ? modal : undefined}
      dropdownRender={mobileScreen ? (menu) => (
        <DropdownModal
          title="Ranch Select"
          displayOptions={menu}
          mobileScreen={mobileScreen}
          onClick={() => setModalState(false)}
        />
      ) : undefined}
      dropdownStyleValues={dropdownStyleValues}
      onDropdownVisibleChange ={ mobileScreen ? () => setModalState(true) : undefined }
    />
  );
};

RanchSelect.propTypes = {
  mode: PropTypes.string,
  loadItems: PropTypes.func,
  renderItem: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
  onChange: PropTypes.func,
  tagRender: PropTypes.func,
  placeholder: PropTypes.string,
  defaultOption: PropTypes.arrayOf(PropTypes.any),
  value: PropTypes.arrayOf(PropTypes.any),
};

RanchSelect.defaultProps = {
  mode: null,
  loadItems: null,
  items: null,
  renderItem: defaultItemRenderer,
  onSelect: null,
  onChange: null,
  tagRender: null,
  placeholder: null,
  defaultOption: [],
  value: null,
};

export default RanchSelect;
