import { farmApi } from 'farmx-api';
import { createFarmSlice } from './farmSlice';

const {
  getRanchList,
  getRanchDetail,
} = farmApi;

const {
  loadListThunk: loadRanchList,
  loadDetailThunk: loadRanchDetail,
  slice: ranchesSlice,
} = createFarmSlice(
  'ranches',
  getRanchList,
  getRanchDetail,
  (state) => state.farmData.ranches,
);

export {
  loadRanchDetail,
  loadRanchList,
};

export default ranchesSlice.reducer;
