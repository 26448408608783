import isNumber from 'lodash/isNumber';

export const stateColorCode = [
  { // Blank 0 white
    iconColor: '#aaaaaa',
    markerColor: 'white',
    fillColor: '#ffffff',
    textColor: '#000000',
  },
  { // Offline 1 gray
    iconColor: '#ffffff',
    markerColor: '#cccccc',
    fillColor: '#cccccc',
    textColor: '#000000',
  },
  { // Bad 2 red
    iconColor: '#ff98a4',
    markerColor: '#e23549',
    fillColor: '#e23549',
    textColor: '#35e2ce',
  },
  { // Warning 3 yellow
    iconColor: '#fff4cc',
    markerColor: '#FFCC00',
    fillColor: '#FFCC00',
    textColor: '#0033ff',
  },
  { // Over - 4 blue
    iconColor: '#a0ccff',
    markerColor: '#429aff',
    fillColor: '#429aff',
    textColor: '#fa742',
  },
  { // Good - 5 green
    iconColor: '#4DFA90',
    markerColor: '#3dc873',
    fillColor: '#3dc873',
    textColor: '#c83d92',
  },
];

export const icons = {
  dendrometer: 'icon-leaf',
  farmx_dendrometer: 'icon-leaf',
  ir_plant: 'icon-camera',
  weather_station: 'icon-ios-rainy',
  water_pressure: 'icon-gauge',
  water_flow: 'icon-waterdrop',
  water_flow_analog: 'icon-waterdrop',
  gateway: 'icon-radio-tower',
  boxer_station: 'icon-radio-tower',
  aquacheck_soil: 'icon-eyedropper',
  pixl_soil: 'icon-eyedropper',
  cavalier: 'icon-eyedropper',
};

export const sensorTitles = {
  dendrometer: {
    singular: 'Dendrometer',
    plural: 'Dendrometers',
  },
  farmx_dendrometer: {
    singular: 'FarmX Dendrometer',
    plural: 'FarmX Dendrometers',
  },
  ir_plant: {
    singular: 'IR Sensor',
    plural: 'IR Sensors',
  },
  weather_station: {
    singular: 'Weather Station',
    plural: 'Weather Stations',
  },
  water_pressure: {
    singular: 'Pressure Sensor',
    plural: 'Pressure Sensors',
  },
  water_flow: {
    singular: 'Flow Meter',
    plural: 'Flow Meters',
  },
  water_flow_analog: {
    singular: 'Flow Meter Analog',
    plural: 'Flow Meters Analog',
  },
  gateway: {
    singular: 'Gateway',
    plural: 'Gateways',
  },
  boxer_station: {
    singular: 'Boxer Station',
    plural: 'Boxer Stations',
  },
  aquacheck_soil: {
    singular: 'Aquacheck Soil Sensor',
    plural: 'Aquacheck Soil Sensors',
  },
  pixl_soil: {
    singular: 'Pixl Soil Sensor',
    plural: 'Pixl Soil Sensors',
  },
  cavalier: {
    singular: 'Cavalier',
    plural: 'Cavaliers',
  },
};

/**
  aquacheck_soil,
  cavalier,
  farmx_dendrometer,
  farmx_frost,
  farmx_infrared,
  gateway,
  pixl_soil,
  valve,
  vfd,
  water_flow,
  water_flow_analog,
  water_pressure,
  weather_station,
*/
export const mapPresentationModes = {
  connectivity: {
    title: 'Connectivity',
    excludedSensors: ['cavalier'],
  },
  waterpressure: {
    title: 'Water pressure',
    includedSensors: ['water_pressure'],
    psiLevel: 5,
  },
  provision: {
    title: 'Provision',
    excludedSensors: [],
  },
};

export function sensorTypeAllowedForMode(presentationMode, sensorType) {
  if (mapPresentationModes[presentationMode].excludedSensors
    && !mapPresentationModes[presentationMode].includedSensors) {
    return !mapPresentationModes[presentationMode].excludedSensors.includes(sensorType);
  }

  if (mapPresentationModes[presentationMode].includedSensors
    && !mapPresentationModes[presentationMode].excludedSensors) {
    return mapPresentationModes[presentationMode].includedSensors.includes(sensorType);
  }

  if (!mapPresentationModes[presentationMode]) {
    throw new Error(`${presentationMode} is not defined in mapPresentationModes`);
  }

  throw new Error('mapPresentationModes can not have both includedSensors and excludedSensors');
}

export function prepareNumberForDisplay(n) {
  if (!isNumber(n)) {
    return n;
  }

  if (Math.abs(n) < 1) {
    return n.toFixed(3);
  }

  return n.toFixed(1);
}

export function getMarkerTooltipBuyPresentationMode(feature, presentationMode) {
  if (presentationMode === 'waterpressure') {
    return prepareNumberForDisplay(feature.properties.psi);
  }
  return feature.properties.identifier.slice(-5);
}
