import React from 'react';
import PropTypes from 'prop-types';
import ScheduleForm from './ScheduleForm';

const ScheduleUpdateForm = ({
  onCancel,
  onChange,
  onSubmit,
  blockId,
  scheduleId,
}) => (
  <ScheduleForm
    onCancel={onCancel}
    onChange={onChange}
    onSubmit={onSubmit}
    blocks={[blockId]}
    scheduleId={scheduleId}
  />
);

ScheduleUpdateForm.propTypes = {
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  blockId: PropTypes.number,
  scheduleId: PropTypes.number,
};

ScheduleUpdateForm.defaultProps = {
  onCancel: () => null,
  onChange: () => null,
  onSubmit: () => null,
  blockId: null,
  scheduleId: null,
};

export default ScheduleUpdateForm;
