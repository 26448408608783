import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Collapse, Divider, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { actions, selectors } from 'farmx-redux-core';
import { useSelector, useDispatch } from 'react-redux';
import StoplightTag from '../StoplightTag';
import BooleanTag from '../BooleanTag';
import ControlSummaryRefreshContext from './RefreshContext';
import styles from './CommonStyles.less';

const {
  selectControlGatewaysForRanchId,
  populateSensorStatus,
} = selectors;

const {
  loadSensorStatus,
} = actions;

function sortByName(a, b) {
  if (!a.name) return -1;
  if (!b.name) return 1;
  return a.name.localeCompare(b.name);
}

export default function ControlSystemStatus({ ranchId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const refreshId = useContext(ControlSummaryRefreshContext);

  const gateways = useSelector((state) => selectControlGatewaysForRanchId(state, ranchId));
  const gatewaysWithStatus = useSelector((state) => populateSensorStatus(state, gateways));
  const gatewaysSorted = gatewaysWithStatus.sort(sortByName);
  const gatewayIdentifiers = JSON.stringify(gatewaysSorted.map((gateway) => gateway.identifier));

  const statusLabels = [
    t('operational'),
    t('degraded'),
    t('offline'),
  ];

  useEffect(() => {
    if (refreshId) {
      JSON.parse(gatewayIdentifiers).forEach((identifier) => dispatch(loadSensorStatus({
        identifier,
        type: 'gateway',
      })));
    }
  }, [dispatch, gatewayIdentifiers, refreshId]);

  function getOverallStatus(sensors) {
    let numInternetOffline = 0;
    let numControlOffline = 0;
    let numDegraded = 0;
    let numStatus = 0;
    sensors.forEach((sensor) => {
      if (sensor.status && sensor.status.controlStatus) {
        numStatus += 1;
        if (sensor.status.online === false) numInternetOffline += 1;
        if (sensor.status.controlStatus.controlServerState === 'offline') numControlOffline += 1;
        if (sensor.status.controlStatus.controlServerState === 'degraded') numDegraded += 1;
      }
    });
    // if a single system is offline or down, show error state
    if (numControlOffline > 0 || numInternetOffline > 0 || numStatus === 0) return 'error';
    // if a single system is degraded, show warning state
    if (numDegraded > 0) return 'warning';
    return 'ok';
  }

  function getStoplightStateFromControlState(value) {
    if (value === 'offline') return 'error';
    if (value === 'online') return 'ok';
    if (value === 'degraded') return 'warning';
    return undefined;
  }

  function renderPanelHeader() {
    const overallStatus = getOverallStatus(gatewaysWithStatus);
    return (
      <div>
        <Typography.Text className={styles.spaceAfter} style={{ fontSize: '16px' }}>
          {t('Gateway System Status')}
        </Typography.Text>
        <StoplightTag value={overallStatus} labels={statusLabels} />
      </div>
    );
  }

  function renderGatewayStatus(gateway) {
    const online = gateway.status && gateway.status.online;
    const controlStatusState = (gateway.status && gateway.status.controlStatus
      && gateway.status.controlStatus.controlServerState);
    const controlStoplightState = getStoplightStateFromControlState(controlStatusState);

    return (
      <div key={gateway.id}>
        <div className={styles.gatewayHeader}><strong>{gateway.name}</strong></div>
        <Divider dashed />
        <div className={styles.spaceBetween}>
          <div>{t('Internet Connectivity')}</div>
          <BooleanTag value={online} labels={[statusLabels[0], statusLabels[2]]} />
        </div>
        <Divider dashed />
        <div className={styles.spaceBetween}>
          <div>{t('Gateway Control Software')}</div>
          <StoplightTag value={controlStoplightState} labels={statusLabels} />
        </div>
        <Divider />
      </div>
    );
  }

  return (
    <div className={styles.listContainer}>
      <Collapse
        expandIconPosition="right"
      >
        <Collapse.Panel
          header={renderPanelHeader()}
          key="systemStatus"
          className={styles.collapseHeader}
        >
          {gatewaysSorted.map((gateway) => renderGatewayStatus(gateway))}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}

ControlSystemStatus.propTypes = {
  ranchId: PropTypes.number.isRequired,
};
